import { NotificationType } from '@local/web-design-system';
import { cloneDeep, isEmpty, partition } from 'lodash-es';
import { NavigateFunction } from 'react-router';
import { Dispatch } from 'redux';
import {
    Observable,
    concatMap,
    mergeMap,
    from as observableFrom,
    of as observableOf,
    toArray,
    catchError,
    map,
} from 'rxjs';
import {
    ACTIVITY_TYPES,
    ALL_ITEM_KEY,
    CONFIGURATION_TYPES,
    DATATABLE_TYPES,
    HEADER_TYPES,
    SPECIAL_TABLE_TYPES,
} from 'state-domains/constants/mxdeposit';
import { loadRankedColumns } from 'state-domains/domain/drillhole/shim/loadRankedColumns';
import { EvoConfigurationState } from 'state-domains/domain/integrations';
import {
    Activity,
    ActivityUser,
    Project,
    ProjectFileGroup,
    projectShim,
    projectState,
} from 'state-domains/domain/project';
import {
    Account,
    AccountUser,
    AccountUserSubscription,
    ActivityGroupMap,
    ActivityMap,
    Field,
    GridEntry,
    Header,
    HeaderType,
    LabCertificateValidation,
    LimitedColumns,
    List,
    ListSpec,
    Operations,
    Row,
    SampleWorkflow,
    Section,
    subscriptionState,
    TableReference,
    UsersByType,
    UserType,
} from 'state-domains/domain/subscription';
import { selectors as userSelectors } from 'state-domains/domain/user/state/selectors';
import { downloadFile, getErrorFromState } from 'state-domains/domain/utils';
import { BaseStateInterface, getState } from 'state-domains/store';
import {
    ItemTypes,
    ModuleTypes,
} from 'src/components/Configuration/ActivitiesProjects/Activities/ActivitiesMasterDetail/ActivitiesDetail/ActivitiesDetail.types';
import { ListSpecAction } from 'src/components/Configuration/ActivitiesProjects/Activities/ActivitiesMasterDetail/ConfigureLists/ConfiguteLists.types';
import { getFiltersForConfiguration } from 'src/components/Configuration/Filter/Filter.utils';
import { WorkflowTypes } from 'src/components/Configuration/Sampling/AnalysisWorkflows/AnalysisWorkflowMasterDetail/AnalysisWorkflowDetail/ActionButton/ActionButton.types';
import { CertificateImportTypes } from 'src/components/Configuration/Sampling/AnalysisWorkflows/AnalysisWorkflowMasterDetail/AnalysisWorkflowDetail/CertificateImport/CertificateImport.types';
import { DataRowType } from 'src/components/DataTable';
import { CONFIGURATION_LISTS_PATH } from 'src/routes';
import { UserPortalState } from 'src/state';
import { GenericObject } from 'src/utilities';
import { formatDateToDisplay } from 'src/utilities/formatters';

import { AsyncStateError, GetState } from '../../../types';
import {
    ADD_SNACKBAR_NOTIFICATION,
    CLEAR_CONFIGURATION_SECTION,
    LOAD_CONFIGURATION_LISTS,
    LOAD_CONFIGURATION_TABLES,
    LOAD_CONFIGURATION_HEADERS,
    DELETE_CONFIGURATION_LIST,
    CLEAR_CONFIGURATION_OPS_STATE,
    ADD_CONFIGURATION_LIST,
    ADD_CONFIGURATION_TABLE,
    EDIT_CONFIGURATION_LIST,
    EDIT_CONFIGURATION_TABLE,
    CLONE_CONFIGURATION_LIST,
    CLEAR_LISTS_DATA_ITEMS,
    CLEAR_TABLES_ITEMS,
    CLEAR_HEADERS_DATA_ITEMS,
    CLEAR_HEADER_FIELD_TYPES_DATA_ITEMS,
    MODIFY_COLUMN,
    CLEAR_MODIFY_COLUMN,
    MODIFY_CONFIGURATION_DATA_GRID_STATE,
    CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE,
    CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE,
    DELETE_CONFIGURATION_TABLEVIEW,
    ADD_CONFIGURATION_HEADER,
    EDIT_CONFIGURATION_HEADER,
    DELETE_CONFIGURATION_HEADER,
    DELETE_CONFIGURATION_FIELD,
    ADD_HEADER_FIELD,
    LOAD_CONFIGURATION_HEADER_FIELD_TYPES,
    CREATE_UPDATE_DELETE_KEY_IN_COLLECTION_ITEM_SUBSCRIPTION_STATE,
    ADD_CONFIGURATION_HEADER_FIELD_TYPES,
    EDIT_CONFIGURATION_HEADER_FIELD_TYPES,
    CLEAR_HEADER_FIELD_TYPES_DELETE_STATE,
    CLEAR_HEADER_FIELD_TYPES_ADD_STATE,
    IMPORT_LIST,
    CLEAR_IMPORT_LIST_STATE,
    CONFIRM_COLUMN_TYPES,
    DELETE_IMPORTED_LIST,
    IMPORT_TABLE,
    CLEAR_IMPORT_TABLE_STATE,
    CREATE_UPDATE_DELETE_COLLECTION_ITEM_LIST_SUBSCRIPTION_STATE,
    EXPORT_CONFIGURATION,
    CLEAR_EXPORT_STATE,
    LOAD_CONFIGURATION_ACTIVITIES,
    CLEAR_ACTIVITIES_DATA_ITEMS,
    DELETE_CONFIGURATION_ACTIVITY,
    CLONE_CONFIGURATION_ACTIVITY,
    ADD_CONFIGURATION_ACTIVITY,
    EDIT_CONFIGURATION_ACTIVITY,
    LOAD_POSSIBLE_SAMPLE_RESULTS_COLUMNS,
    ADD_SAMPLE_RESULTS_RANKED_COLUMN,
    MODIFY_SAMPLE_WORKFLOWS,
    LOAD_CONFIGURATION_RANKED_COLUMNS,
    EDIT_RANKED_COLUMN,
    DELETE_RANKED_COLUMN,
    CLEAR_RANKED_COLUMNS_STATE,
    CLEAR_POSSIBLE_COLUMNS,
    LOAD_CONFIGURATION_PROJECTS,
    CLEAR_PROJECTS_DATA_ITEMS,
    CLONE_CONFIGURATION_PROJECT,
    ADD_CONFIGURATION_PROJECT,
    CREATE_UPDATE_DELETE_COLLECTION_ITEM_PROJECT_STATE,
    DELETE_CONFIGURATION_PROJECT,
    EDIT_CONFIGURATION_PROJECT,
    CONVERT_PROJECT_BOUNDARIES,
    ADD_CONFIGURATION_PROJECT_ACTIVITY,
    EDIT_CONFIGURATION_PROJECT_ACTIVITIES,
    DELETE_CONFIGURATION_PROJECT_ACTIVITY,
    CONVERT_BOUNDARY_TO_DECIMAL,
    ASSIGN_UNASSIGN_USERS,
    LOAD_USER_PERMISSIONS,
    MODIFY_LIST_FILES,
    MODIFY_LIST_FILE_GROUP,
    CLEAR_LIST_FILE_STATE,
    EMPTY_LIST_FILE_GROUPS,
    LOAD_CONFIGURATION_SAMPLE_WORKFLOWS,
    CLEAR_CONFIGURATION_SAMPLE_WORKFLOW_STATE,
    CLEAR_CONFIGURATION_SAMPLE_WORKFLOW_ADD_STATE,
    ADD_CONFIGURATION_SAMPLE_WORKFLOW,
    EDIT_CONFIGURATION_SAMPLE_WORKFLOW,
    CLONE_CONFIGURATION_SAMPLE_WORKFLOW,
    DELETE_CONFIGURATION_SAMPLE_WORKFLOW,
    LOAD_DEFAULT_LAB_SERVICES,
    LOAD_CONFIGURATION_CATEGORIES,
    LOAD_CONFIGURATION_ACTIVITY_GROUPS,
    LOAD_COORDINATES,
    LOAD_CONFIGURATION_GRIDS,
    EDIT_CONFIGURATION_GRID,
    ADD_CONFIGURATION_GRID,
    LOAD_DEFAULT_PROJECTIONS,
    LOAD_GRID_PREVIEW,
    DELETE_CONFIGURATION_GRID,
    CLEAR_GRIDS_DATA_ITEMS,
    UPDATE_GRID_NAME,
    LOAD_CONFIGURATION_USERS,
    OP_CONFIGURATION_USERS,
    CONFIGURATION_CATEGORIES_OPERATION,
    ADD_EDIT_DELETE_ACTIVITY_GROUPS,
    RELOAD_CONFIGURATION_ITEMS,
    CLEAR_ACTIVITY_GROUPS_DATA_ITEMS,
    LOAD_EVO_ORG,
    EVO_CONFIGURATION,
    LOAD_EVO_WORKSPACES,
    EVO_DISCOVERY,
    LOAD_HEADERS,
} from '../../../types/actionTypes';
import {
    convertToCamel,
    convertToSnake,
    typeComplete,
    typeFail,
    typePending,
} from '../../../utils';
import { configurationShim } from '../shim';
import {
    ConfigurationCreateListRequest,
    ConfigurationList,
    ConfigurationListResponse,
    ConfigurationHeadersResponse,
    ConfigurationTablesResponse,
    ConfigurationCreateHeaderRequest,
    ConfigurationHeaderResponse,
    BulkValidateImportList,
    ConfigurationCreateActivityRequest,
    RankedColumnType,
    ConfigurationProject,
    UnassignPayload,
    AssignPayload,
    AssignUnassignPayloadType,
    AssignUnassignObjectType,
    EditConfigurationProjectActivity,
    ConfigurationCreateWorkflowRequest,
    DefaultLabServiceCollections,
    CATEGORY_TYPES,
    AssignCoordinatesPayload,
    LoadAccountInformationResponse,
    UserItem,
} from '../types';
import {
    determineSpecialTable,
    dispatchProjectCollections,
    generateBulkImportPayload,
    getChildTables,
    sortAndReIndex,
    updateActivitiesInProjectCollections,
} from '../utils';

const { currentUserId } = userSelectors;

const getFilters = (type: CONFIGURATION_TYPES, userId: string, subscriptionId: string) =>
    getFiltersForConfiguration(type, userId, subscriptionId);

const loadConfigurationLists =
    (configurationType: CONFIGURATION_TYPES = CONFIGURATION_TYPES.LISTS) =>
    (dispatch: Dispatch, getState: GetState) => {
        const userId = getState().user?.id ?? '';
        const subscriptionId = getState().user?.selected?.id;
        const filters = getFilters(configurationType, userId, subscriptionId ?? '');
        dispatch({ type: typePending(LOAD_CONFIGURATION_LISTS) });
        return configurationShim.loadConfigurationLists(filters).subscribe({
            next: (configurationListsResponse: { items: ConfigurationList[] }) =>
                dispatch({
                    type: typeComplete(LOAD_CONFIGURATION_LISTS),
                    payload: configurationListsResponse,
                }),
            error: (error: Error) => {
                dispatch({ type: typeFail(LOAD_CONFIGURATION_LISTS), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const loadConfigurationTableViews = () => (dispatch: Dispatch, getState: GetState) => {
    const allState = getState();
    const userId = currentUserId(allState);
    const subscriptionId = getState().user?.selected?.id;
    const filters = getFilters(CONFIGURATION_TYPES.TABLES, userId, subscriptionId ?? '');
    dispatch({ type: typePending(LOAD_CONFIGURATION_TABLES) });
    return configurationShim
        .loadConfigurationTableViews(filters.filter((x) => x.field !== 'special_table'))
        .subscribe({
            next: (configurationTablesResponse: ConfigurationTablesResponse) => {
                const response = configurationTablesResponse;
                const childFilter = filters.find((x) => x.field === 'parent');
                const specialFilter = filters.find((x) => x.field === 'special_table');

                if (childFilter) {
                    const tables = allState.subscription?.tables ?? {};
                    response.items = response.items.filter((x) =>
                        childFilter.operator === 'has_values'
                            ? !!tables[x.id]?.parent
                            : !tables[x.id]?.parent,
                    );
                }

                const tables = allState.subscription?.tables;

                response.items = response.items.map((x) => {
                    const collections = determineSpecialTable(x, tables ?? {});

                    if (!collections) {
                        return x;
                    }

                    const { types } = collections;

                    return {
                        ...x,
                        specialTables: types.length ? types : [SPECIAL_TABLE_TYPES.OTHER],
                    };
                });

                if (specialFilter && specialFilter.operator !== 'has_values') {
                    if (specialFilter.operator === 'one_of') {
                        const convertedValues: any[] = specialFilter.values.includes(ALL_ITEM_KEY)
                            ? Object.values(SPECIAL_TABLE_TYPES)
                            : specialFilter.values;

                        response.items = response.items.filter((x) =>
                            convertedValues.some((y) => x.specialTables.includes(y)),
                        );
                    } else {
                        response.items = [];
                    }
                }

                dispatch({
                    type: typeComplete(LOAD_CONFIGURATION_TABLES),
                    payload: response,
                });
            },
            error: (error: Error) => {
                dispatch({ type: typeFail(LOAD_CONFIGURATION_TABLES), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
};

const loadConfigurationHeaders =
    (configType: CONFIGURATION_TYPES) => (dispatch: Dispatch, getState: GetState) => {
        const userId = getState().user?.id ?? '';
        const subscriptionId = getState().user?.selected?.id;
        const filters = getFilters(configType, userId, subscriptionId ?? '');
        dispatch({ type: typePending(LOAD_CONFIGURATION_HEADERS) });
        return configurationShim.loadConfigurationHeaders(configType, filters).subscribe({
            next: (configurationHeadersResponse: ConfigurationHeadersResponse) => {
                dispatch({
                    type: typeComplete(LOAD_CONFIGURATION_HEADERS),
                    payload: configurationHeadersResponse,
                });
            },
            error: (error: Error) => {
                dispatch({ type: typeFail(LOAD_CONFIGURATION_HEADERS), payload: { error } });
            },
        });
    };

const removeNotFetchedItemsFromSelectClearState = (
    items: any[],
    allState: UserPortalState,
    dispatch: Dispatch,
) => {
    const selectedItems = allState.subscription?.selectedClearState.selectedItems ?? {};
    const unSelectedItems = allState.subscription?.selectedClearState.unSelectedItems ?? {};
    const toRemoveFromSelectedUnSelected = [];
    for (const key of [...Object.keys(selectedItems), ...Object.keys(unSelectedItems)]) {
        const item = items.find((x) => x.id === key);
        if (!item) {
            toRemoveFromSelectedUnSelected.push({ id: key });
        }
    }
    if (toRemoveFromSelectedUnSelected.length) {
        dispatchUpdateSelectClear(toRemoveFromSelectedUnSelected, allState, dispatch, true);
    }
};

const addNewAttribute = (obj: GenericObject, newAttribute: string, newValue: string) => {
    for (const key in obj) {
        if (key in obj) {
            obj[key][newAttribute] = newValue;
        }
    }
    return obj;
};

const loadConfigurationHeaderFieldTypes =
    (configType: CONFIGURATION_TYPES) => (dispatch: Dispatch, getState: GetState) => {
        const allState = getState() as UserPortalState;
        const userId = allState.user?.id ?? '';
        const subscriptionId = allState.user?.selected?.id;
        const filters = getFilters(configType, userId, subscriptionId ?? '');
        dispatch({ type: typePending(LOAD_CONFIGURATION_HEADER_FIELD_TYPES) });
        return configurationShim.loadConfigurationHeaderFieldTypes(filters).subscribe({
            next: (r: { items: HeaderType[] }) => {
                removeNotFetchedItemsFromSelectClearState(r.items, allState, dispatch);
                const fieldsMap = Object.values({
                    ...addNewAttribute(
                        cloneDeep(allState.subscription.sampleDispatchHeaders ?? {}),
                        'type',
                        HEADER_TYPES.DISPATCH,
                    ),
                    ...addNewAttribute(
                        cloneDeep(allState.subscription.headers ?? {}),
                        'type',
                        HEADER_TYPES.LOGGING,
                    ),
                })
                    .flatMap((x) => [
                        ...Object.values(x.sections).map((y: any) => ({
                            ...y,
                            id: x.id,
                            name: x.name,
                            type: x.type,
                        })),
                        ...(x.dispatchDateField
                            ? [
                                  {
                                      fields: {
                                          [x.dispatchDateField]: { field: x.dispatchDateField },
                                      },
                                      id: x.id,
                                      name: x.name,
                                      type: x.type,
                                  },
                              ]
                            : []),
                        ...(x.dispatchedByField
                            ? [
                                  {
                                      fields: {
                                          [x.dispatchedByField]: { field: x.dispatchedByField },
                                      },
                                      id: x.id,
                                      name: x.name,
                                      type: x.type,
                                  },
                              ]
                            : []),
                    ])
                    .flatMap((x) =>
                        Object.values(x.fields).map((y: any) => ({
                            ...y,
                            id: x.id,
                            name: x.name,
                            type: x.type,
                        })),
                    )
                    .reduce((accum: any, value: any) => {
                        if (value.field) {
                            accum[value.field] = accum[value.field] ?? {};

                            accum[value.field][value.id] = {
                                id: value.id,
                                name: value.name,
                                type: value.type,
                            };
                        }

                        return accum;
                    }, {} as any);

                const headerFieldTypes = r.items?.map((x) => ({ ...x, usedIn: fieldsMap[x.id] }));
                dispatch({
                    type: typeComplete(LOAD_CONFIGURATION_HEADER_FIELD_TYPES),
                    payload: { items: headerFieldTypes },
                });
            },
            error: (error: Error) => {
                dispatch({
                    type: typeFail(LOAD_CONFIGURATION_HEADER_FIELD_TYPES),
                    payload: { error },
                });
            },
        });
    };
const clearHeadersItems = () => (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_HEADERS_DATA_ITEMS });
};
const clearHeaderFieldTypesItems = () => (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_HEADER_FIELD_TYPES_DATA_ITEMS });
};
const clearHeaderFieldTypesAddState = () => (dispatch: Dispatch) => {
    dispatch({ type: typeComplete(CLEAR_HEADER_FIELD_TYPES_ADD_STATE) });
};
const clearHeaderFieldTypesDeleteState = () => (dispatch: Dispatch) => {
    dispatch({ type: typeComplete(CLEAR_HEADER_FIELD_TYPES_DELETE_STATE) });
};
const clearTablesItems = () => (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_TABLES_ITEMS });
};
const clearListsItems = () => (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_LISTS_DATA_ITEMS });
};
const clearGridsItems = () => (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_GRIDS_DATA_ITEMS });
};
const clearActivityItems = () => (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_ACTIVITIES_DATA_ITEMS });
};
const clearProjectItems = () => (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_PROJECTS_DATA_ITEMS });
};

const clearActivityGroupsItems = () => (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_ACTIVITY_GROUPS_DATA_ITEMS });
};

const addConfigurationHeaderFieldType =
    (payload: any, id = '', isSample = false) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(ADD_CONFIGURATION_HEADER_FIELD_TYPES) });
        return configurationShim.addConfigurationHeaderFieldType(payload, id).subscribe({
            next: (response: HeaderType) => {
                dispatch({
                    type: typeComplete(
                        id
                            ? EDIT_CONFIGURATION_HEADER_FIELD_TYPES
                            : ADD_CONFIGURATION_HEADER_FIELD_TYPES,
                    ),
                    payload: response,
                });
                if (id) {
                    // In case of edit update the selectedClearState items
                    const allState = getState() as UserPortalState;
                    dispatchUpdateSelectClear([response], allState, dispatch, false);
                }
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id: response.id,
                        item: response,
                        collectionName: 'headerTypes',
                        type: 'PUT',
                    },
                });

                const i18nKey = `${isSample ? 'headerSampleField' : 'headerField'}${id ? 'Edited' : 'Added'}`;
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: {
                        type: NotificationType.SUCCESS,
                        i18nKey,
                        i18nValues: { name: response.name },
                    },
                });
            },
            error: (error: any) => {
                dispatch({
                    type: typeFail(ADD_CONFIGURATION_HEADER_FIELD_TYPES),
                    payload: { error },
                });
            },
        });
    };
const addConfigurationList = (payload: any) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(ADD_CONFIGURATION_LIST) });
    return configurationShim.addConfigurationList(payload).subscribe({
        next: (response: ConfigurationListResponse) => {
            dispatch({
                type: typeComplete(ADD_CONFIGURATION_LIST),
                payload: response,
            });
            dispatch({
                type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                payload: { id: response.id, item: response, collectionName: 'lists', type: 'PUT' },
            });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: {
                    type: NotificationType.SUCCESS,
                    i18nKey: 'listItemCreated',
                    i18nValues: { name: response.name },
                },
            });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(ADD_CONFIGURATION_LIST), payload: { error } });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const addConfigurationTable =
    (payload: any, isTableView: boolean, showSnackbar = true) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(ADD_CONFIGURATION_TABLE) });
        return configurationShim.addConfigurationTable(payload, isTableView).subscribe({
            next: (result: { table: any; isTableView: boolean }) => {
                const { table: response, isTableView } = result;

                const modifyTableViewPayload = () => {
                    let result;

                    if (isTableView) {
                        result = response;
                    } else {
                        const {
                            columns: _columns,
                            parent: _parent,
                            tableView: _tableView,
                            ...rest
                        } = response;
                        result = { ...rest, singleTable: response.id, tables: {} };
                    }

                    return result;
                };

                dispatch({
                    type: typeComplete(ADD_CONFIGURATION_TABLE),
                    payload: response,
                });
                if (!isTableView) {
                    dispatch({
                        type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                        payload: {
                            id: response.id,
                            item: response,
                            collectionName: 'tables',
                            type: 'PUT',
                        },
                    });
                }
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id: response.id,
                        item: { ...modifyTableViewPayload() },
                        collectionName: 'tableViews',
                        type: 'PUT',
                    },
                });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: {
                        type: NotificationType.SUCCESS,
                        i18nKey: isTableView ? 'tableViewItemCreated' : 'tableItemCreated',
                        i18nValues: { name: response.name },
                    },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(ADD_CONFIGURATION_TABLE), payload: { error } });

                if (showSnackbar)
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
            },
        });
    };

const dispatchUpdateSelectClear = (
    items: any[],
    allState: UserPortalState,
    dispatch: Dispatch,
    isDelete: boolean,
) => {
    const selectedItems = { ...allState.subscription.selectedClearState.selectedItems };
    const unselectedItems = { ...allState.subscription.selectedClearState.unSelectedItems };
    if (isDelete) {
        for (const item of items) {
            const id = item.id ?? '';
            delete selectedItems[id];
            delete unselectedItems[id];
        }
    } else {
        for (const item of items) {
            const id = item.id ?? '';
            // Need to correctly format the date fields, requires a bigger change to do it in properties panel
            const formattedItem = {
                ...item,
                updatedAt: formatDateToDisplay(item.updatedAt),
                createdAt: formatDateToDisplay(item.createdAt),
                createdAtEpoch: item.createdAt?.date,
                updatedAtEpoch: item.updatedAt?.date,
                expiresOnEpoch: item.expires?.date,
            };
            if (selectedItems[id]) {
                selectedItems[id] = formattedItem;
            }
            if (unselectedItems[id]) {
                unselectedItems[id] = formattedItem;
            }
        }
    }
    dispatch({
        type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
        payload: {
            id: 'selectedItems',
            item: selectedItems,
            collectionName: 'selectedClearState',
            type: 'PUT',
        },
    });
    dispatch({
        type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
        payload: {
            id: 'unSelectedItems',
            item: unselectedItems,
            collectionName: 'selectedClearState',
            type: 'PUT',
        },
    });
};

const deleteConfigurationList =
    (id: string) => (dispatch: Dispatch, getState: () => BaseStateInterface) => {
        dispatch({ type: typePending(DELETE_CONFIGURATION_LIST), payload: id });
        return configurationShim.deleteConfigurationLists(id).subscribe({
            next: (response: any) => {
                const allState = getState() as UserPortalState;
                dispatch({ type: typeComplete(DELETE_CONFIGURATION_LIST), payload: response });
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: { id, item: { id }, collectionName: 'lists', type: 'DELETE' },
                });
                dispatchUpdateSelectClear([{ id }], allState, dispatch, true);
            },
            error: (error: any) => {
                dispatch({ type: typeFail(DELETE_CONFIGURATION_LIST), payload: { error } });
            },
        });
    };

const deleteConfigurationTableView =
    (id: string) => (dispatch: Dispatch, getState: () => UserPortalState) => {
        dispatch({ type: typePending(DELETE_CONFIGURATION_TABLEVIEW), payload: id });
        return configurationShim.deleteConfigurationTableView(id).subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(DELETE_CONFIGURATION_TABLEVIEW), payload: response });
                const allState = getState();
                const tableView = allState.subscription.tableViews[id];
                if (tableView.singleTable) {
                    dispatch({
                        type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                        payload: { id, item: { id }, collectionName: 'tables', type: 'DELETE' },
                    });
                }
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: { id, item: { id }, collectionName: 'tableViews', type: 'DELETE' },
                });
                dispatchUpdateSelectClear([{ id }], allState, dispatch, true);
            },
            error: (error: any) => {
                dispatch({ type: typeFail(DELETE_CONFIGURATION_TABLEVIEW), payload: { error } });
            },
        });
    };
const editList = (id: string | undefined, payload: any) => (dispatch: Dispatch) => {
    if (!id) {
        return;
    }
    dispatch({ type: typePending(EDIT_CONFIGURATION_LIST) });
    return configurationShim.saveList(id, payload).subscribe({
        next: (item: List) => {
            dispatch({
                type: typeComplete(EDIT_CONFIGURATION_LIST),
                payload: item,
            });
            dispatch({
                type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                payload: { id: item.id, item, collectionName: 'lists', type: 'PUT' },
            });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: {
                    type: NotificationType.SUCCESS,
                    i18nKey: 'listEdited',
                    i18nValues: { name: item.name },
                },
            });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(EDIT_CONFIGURATION_LIST), payload: error });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const editTable =
    (id: string | undefined, payload: any, isTableView: boolean) =>
    (dispatch: Dispatch, getState: Function) => {
        if (!id) {
            return;
        }
        dispatch({ type: typePending(EDIT_CONFIGURATION_TABLE) });
        return configurationShim.saveTable(id, payload, isTableView).subscribe({
            next: (result: { table: any; isTableView: boolean }) => {
                const allState = getState() as UserPortalState;
                const { table: item, isTableView } = result;
                const tableObject = cloneDeep(allState.subscription.tables[item.id] ?? {});
                const tableViewObject = cloneDeep(allState.subscription.tableViews[item.id] ?? {});

                const convertedTables = [];
                const convertedTableViews = [];

                if (!!tableObject.category && tableObject.category !== item.category) {
                    const childTables = getChildTables(
                        tableViewObject,
                        allState.subscription.tables,
                    );
                    convertedTables.push(
                        ...childTables.map((x) => ({ ...cloneDeep(x), category: item.category })),
                    );
                    convertedTableViews.push(
                        ...childTables.map((x) => ({
                            ...cloneDeep(allState.subscription.tableViews[x.id]),
                            category: item.category,
                        })),
                    );
                }

                const modifyTableViewPayload = () => {
                    let result;

                    if (isTableView) {
                        result = item;
                    } else {
                        const {
                            columns: _columns,
                            parent: _parent,
                            tableView: _tableView,
                            ...rest
                        } = item;
                        result = { ...rest };
                    }

                    return result;
                };

                dispatch({
                    type: typeComplete(EDIT_CONFIGURATION_TABLE),
                    payload: item,
                });
                if (!isTableView) {
                    dispatch({
                        type: typeComplete(
                            CREATE_UPDATE_DELETE_COLLECTION_ITEM_LIST_SUBSCRIPTION_STATE,
                        ),
                        payload: {
                            items: [{ ...tableObject, ...item }, ...convertedTables],
                            collectionName: 'tables',
                            type: 'PUT',
                        },
                    });
                }
                dispatch({
                    type: typeComplete(
                        CREATE_UPDATE_DELETE_COLLECTION_ITEM_LIST_SUBSCRIPTION_STATE,
                    ),
                    payload: {
                        items: [
                            { ...tableViewObject, ...modifyTableViewPayload() },
                            ...convertedTableViews,
                        ],
                        collectionName: 'tableViews',
                        type: 'PUT',
                    },
                });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: {
                        type: NotificationType.SUCCESS,
                        i18nKey: 'tableEdited',
                        i18nValues: { name: item.name },
                    },
                });
            },
            error: (_error: any) => {
                dispatch({ type: typeFail(EDIT_CONFIGURATION_TABLE), payload: null });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, i18nKey: 'editTableFailed' },
                });
            },
        });
    };

const deleteConfigurationHeader =
    (id: string, configType = CONFIGURATION_TYPES.HEADERS) =>
    (dispatch: Dispatch, getState: () => BaseStateInterface) => {
        dispatch({ type: typePending(DELETE_CONFIGURATION_HEADER), payload: id });
        return configurationShim.deleteConfigurationHeader(id, configType).subscribe({
            next: (response: any) => {
                const allState = getState() as UserPortalState;
                dispatch({ type: typeComplete(DELETE_CONFIGURATION_HEADER), payload: response });
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id,
                        item: { id },
                        collectionName:
                            configType === CONFIGURATION_TYPES.HEADERS
                                ? 'headers'
                                : 'sampleDispatchHeaders',
                        type: 'DELETE',
                    },
                });
                dispatchUpdateSelectClear([{ id }], allState, dispatch, true);
            },
            error: (error: any) => {
                dispatch({ type: typeFail(DELETE_CONFIGURATION_HEADER), payload: { error } });
            },
        });
    };

const deleteConfigurationField =
    (id: string) => (dispatch: Dispatch, getState: () => BaseStateInterface) => {
        dispatch({ type: typePending(DELETE_CONFIGURATION_FIELD), payload: id });
        return configurationShim.deleteConfigurationField(id).subscribe({
            next: (response: any) => {
                const allState = getState() as UserPortalState;
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id,
                        item: { id },
                        collectionName: 'header-field-types',
                        type: 'DELETE',
                    },
                });

                dispatchUpdateSelectClear([{ id }], allState, dispatch, true);
                dispatch({ type: typeComplete(DELETE_CONFIGURATION_FIELD), payload: response });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(DELETE_CONFIGURATION_FIELD), payload: { error } });
            },
        });
    };

const cloneConfigurationList =
    (request: ConfigurationCreateListRequest, _showSnackbar = true) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(CLONE_CONFIGURATION_LIST), payload: request });
        return configurationShim.addConfigurationList(request).subscribe({
            next: (response: ConfigurationListResponse) => {
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id: response.id,
                        item: response,
                        collectionName: 'lists',
                        type: 'PUT',
                    },
                });
                dispatch({ type: typeComplete(CLONE_CONFIGURATION_LIST), payload: response });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(CLONE_CONFIGURATION_LIST), payload: { error } });
            },
        });
    };

const cloneConfigurationActivity =
    (request: ConfigurationCreateActivityRequest, _showSnackbar = true) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(CLONE_CONFIGURATION_ACTIVITY), payload: request });
        const updatedPayload = { ...request, published: true };
        return configurationShim.addConfigurationActivity(updatedPayload).subscribe({
            next: (response: ActivityMap) => {
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id: response.id,
                        item: response,
                        collectionName: 'activities',
                        type: 'PUT',
                    },
                });
                dispatch({ type: typeComplete(CLONE_CONFIGURATION_ACTIVITY), payload: response });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(CLONE_CONFIGURATION_ACTIVITY), payload: { error } });
            },
        });
    };

const modifyListRow =
    (id: string, rowPayload: any, disablePending = false) =>
    (dispatch: Dispatch, _getState: GetState) => {
        if (!disablePending) {
            dispatch({ type: typePending(MODIFY_CONFIGURATION_DATA_GRID_STATE) });
        }
        return configurationShim.saveListRow(id, rowPayload).subscribe({
            next: (value: Row) => {
                dispatch({
                    type: typeComplete(MODIFY_CONFIGURATION_DATA_GRID_STATE),
                });
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE),
                    payload: { id, collectionName: 'lists', item: value, keyInCollection: 'rows' },
                });
            },
            error: (error: any) => {
                dispatch({
                    type: typeFail(MODIFY_CONFIGURATION_DATA_GRID_STATE),
                    payload: error,
                });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const modifyListRowWithValidation =
    (id: string, validatePayload: any, rowPayload: any) =>
    (dispatch: Dispatch, getState: GetState) => {
        dispatch({ type: typePending(MODIFY_CONFIGURATION_DATA_GRID_STATE) });
        return configurationShim.validateCell(validatePayload).subscribe({
            next: () => {
                modifyListRow(id, rowPayload, true)(dispatch, getState);
            },
            error: (error: any) => {
                dispatch({ type: typeFail(MODIFY_CONFIGURATION_DATA_GRID_STATE), payload: error });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const loadListFileGroup = (groupId: string) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(MODIFY_LIST_FILE_GROUP) });
    return configurationShim.loadListFileGroup(groupId).subscribe({
        next: (response) => {
            dispatch({
                type: typeComplete(MODIFY_LIST_FILE_GROUP),
                payload: response,
            });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(MODIFY_LIST_FILE_GROUP), payload: error });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const createListFileGroupAndSaveRow = (fileGroup: any) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(MODIFY_LIST_FILE_GROUP) });
    return configurationShim.addListFileGroup(fileGroup).subscribe({
        next: (response) => {
            dispatch({
                type: typeComplete(MODIFY_LIST_FILE_GROUP),
                payload: response,
            });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(MODIFY_LIST_FILE_GROUP), payload: error });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const uploadFileToListGroup = (groupId: string, files: any[]) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(MODIFY_LIST_FILE_GROUP) });
    return observableFrom(files)
        .pipe(concatMap((file) => configurationShim.addFileToListGroup(groupId, file)))
        .pipe(toArray())
        .subscribe({
            next: (uploadedFiles) => {
                if (uploadedFiles.length > 0) {
                    // Shim doesn't throw the error object. Need to check the ok property
                    const error: string[] = [];
                    for (const u of uploadedFiles) {
                        if ((u.file as any).ok === false) {
                            error.push(`${u.name} could not be uploaded.`);
                        }
                    }
                    if (error.length) {
                        dispatch({ type: typeFail(MODIFY_LIST_FILE_GROUP), payload: error });
                        dispatch({
                            type: ADD_SNACKBAR_NOTIFICATION,
                            payload: {
                                type: NotificationType.ERROR,
                                message: { message: error.join('\n') },
                            },
                        });
                    } else {
                        dispatch({
                            type: typeComplete(MODIFY_LIST_FILES),
                            payload: {
                                files: uploadedFiles.map((x) => x.file),
                                operation: 'Upload',
                            },
                        });
                    }
                }
            },
        });
};

const clearListFileState = (dispatch: Dispatch) =>
    dispatch({ type: typeComplete(CLEAR_LIST_FILE_STATE) });

const downloadListFile =
    (groupId: string, fileId: string, fileName: string) => (dispatch: Dispatch) => {
        dispatch({ type: typePending(MODIFY_LIST_FILE_GROUP) });
        return configurationShim.downloadListFile(groupId, fileId).subscribe({
            next: (blob) => {
                const url = window.URL.createObjectURL(blob);
                downloadFile(url, fileName);
                dispatch({
                    type: typeComplete(MODIFY_LIST_FILES),
                    payload: { operation: 'Download' },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(MODIFY_LIST_FILE_GROUP), payload: error });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const deleteListFile = (groupId: string, fileId: string) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(MODIFY_LIST_FILE_GROUP) });
    return configurationShim.deleteListFile(groupId, fileId).subscribe({
        next: (file) => {
            dispatch({
                type: typeComplete(MODIFY_LIST_FILES),
                payload: { files: [file], operation: 'Delete' },
            });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(MODIFY_LIST_FILE_GROUP), payload: error });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const loadEmptyListFileGroups = (listIds: string[]) => (dispatch: Dispatch) =>
    observableFrom(listIds)
        .pipe(mergeMap((x) => configurationShim.loadEmptyListFileGroups(x), 5))
        .pipe(toArray())
        .subscribe({
            next: (groups) => {
                dispatch({
                    type: EMPTY_LIST_FILE_GROUPS,
                    payload: groups.reduce((accum, value) => ({ ...accum, ...value }), {}),
                });
            },
            error: (error: any) => {
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });

const deleteListRows =
    (id: string, args: any[], multiDelete: boolean) =>
    (dispatch: Dispatch, _getState: GetState) => {
        dispatch({ type: typePending(MODIFY_CONFIGURATION_DATA_GRID_STATE) });
        const shimCall: (...args: any[]) => Observable<string[]> = multiDelete
            ? configurationShim.deleteListRows
            : configurationShim.deleteListRow;

        return shimCall(...args).subscribe({
            next: (item: string[]) => {
                dispatch({
                    type: typeComplete(MODIFY_CONFIGURATION_DATA_GRID_STATE),
                });
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id,
                        collectionName: 'lists',
                        keyInCollection: 'rows',
                        deleteIds: item,
                    },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(MODIFY_CONFIGURATION_DATA_GRID_STATE), payload: error });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const saveList =
    (id: string, payload: any, sortedSave = false) =>
    (dispatch: Dispatch, _getState: GetState) => {
        dispatch({ type: typePending(MODIFY_CONFIGURATION_DATA_GRID_STATE) });
        return configurationShim.saveList(id, payload).subscribe({
            next: (item: List) => {
                dispatch({
                    type: typeComplete(MODIFY_CONFIGURATION_DATA_GRID_STATE),
                });

                if (!sortedSave) {
                    dispatch({
                        type: typeComplete(CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE),
                        payload: {
                            id,
                            collectionName: 'lists',
                            items: Object.values(item.rows ?? {}),
                            keyInCollection: 'rows',
                        },
                    });
                } else {
                    dispatch({
                        type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                        payload: { id: item.id, item, type: 'PUT', collectionName: 'lists' },
                    });
                }
            },
            error: (error: any) => {
                dispatch({ type: typeFail(MODIFY_CONFIGURATION_DATA_GRID_STATE), payload: error });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const reorderColumns =
    (type: string, id: string, payload: any, state = MODIFY_CONFIGURATION_DATA_GRID_STATE) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(state) });
        return configurationShim.reorderColumns(type, id, payload).subscribe({
            next: (item: LimitedColumns) => {
                dispatch({
                    type: typeComplete(state),
                });
                dispatch({
                    type: typeComplete(
                        CREATE_UPDATE_DELETE_KEY_IN_COLLECTION_ITEM_SUBSCRIPTION_STATE,
                    ),
                    payload: {
                        id,
                        collectionName: type,
                        item,
                        keyInCollection: 'columns',
                    },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(state), payload: error });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const clearConfigurationSection =
    (type: CONFIGURATION_TYPES | DATATABLE_TYPES) => (dispatch: Dispatch) =>
        dispatch({ type: CLEAR_CONFIGURATION_SECTION, payload: type });

const clearConfigurationOperationState = () => (dispatch: Dispatch) =>
    dispatch({ type: CLEAR_CONFIGURATION_OPS_STATE });

const modifyColumn =
    (type: string, itemId: string, payload: any, id = '', columnKey = 'columns') =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(MODIFY_COLUMN) });
        return configurationShim.modifyColumn(type, itemId, payload, id).subscribe({
            next: (column: any) => {
                dispatch({
                    type: typeComplete(MODIFY_COLUMN),
                    payload: column,
                });

                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id: itemId,
                        collectionName: type,
                        item: column,
                        keyInCollection: columnKey,
                    },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(MODIFY_COLUMN), payload: error });
            },
        });
    };

const modifyCoordinateColumn =
    (type: string, itemId: string, payload: any, id = '', _columnKey = 'columns') =>
    (dispatch: Dispatch, getState: GetState) => {
        dispatch({ type: typePending(MODIFY_COLUMN) });
        return configurationShim.modifyColumn(type, itemId, payload, id).subscribe({
            next: (column: any) => {
                dispatch({
                    type: typeComplete(MODIFY_COLUMN),
                    payload: column,
                });

                const header = cloneDeep(getState().subscription?.headers[itemId]);
                if (header) {
                    header.coordinatesTable.columns[column.id] = column;
                    dispatch({ type: typeComplete(LOAD_HEADERS), payload: { result: [header] } });
                }
            },
            error: (error: any) => {
                dispatch({ type: typeFail(MODIFY_COLUMN), payload: error });
            },
        });
    };

const clearModifyColumn = (dispatch: Dispatch) => dispatch({ type: CLEAR_MODIFY_COLUMN });

const deleteTableViewTableColumn =
    (tableViewId: string, tableId: string, columnId: string) => (dispatch: Dispatch) => {
        dispatch({ type: typePending(EDIT_CONFIGURATION_TABLE) });
        return configurationShim.deleteTableViewColumn(tableViewId, tableId, columnId).subscribe({
            next: (column: any) => {
                dispatch({
                    type: typeComplete(EDIT_CONFIGURATION_TABLE),
                    payload: column,
                });
                const payload = {
                    childId: tableId,
                    childKey: 'columns',
                    id: tableViewId,
                    collectionName: 'tableViews',
                    keyInCollection: 'tables',
                    deleteIds: [columnId],
                };
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE),
                    payload,
                });
            },
            error: (error: any) => {
                dispatch({
                    type: typeFail(EDIT_CONFIGURATION_TABLE),
                    payload: error,
                });
            },
        });
    };

const deleteColumn =
    (type: CONFIGURATION_TYPES, itemId: string, columnId: string) =>
    (dispatch: Dispatch, _getState: GetState) => {
        const dispatchType =
            type === CONFIGURATION_TYPES.TABLES ? EDIT_CONFIGURATION_TABLE : MODIFY_COLUMN;
        dispatch({ type: typePending(dispatchType) });
        return configurationShim.deleteColumn(type, itemId, columnId).subscribe({
            next: (column: any) => {
                dispatch({
                    type: typeComplete(dispatchType),
                    payload: column,
                });
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id: itemId,
                        collectionName: type,
                        keyInCollection: 'columns',
                        deleteIds: [columnId],
                    },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(dispatchType), payload: error });
            },
        });
    };

const addTableToTableView =
    (tableViewId: string, payload: any, tableId = '') =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(MODIFY_COLUMN) });
        return configurationShim.addTableToTableView(tableViewId, payload, tableId).subscribe({
            next: (result: TableReference) => {
                dispatch({
                    type: typeComplete(MODIFY_COLUMN),
                });
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id: tableViewId,
                        item: result,
                        collectionName: 'tableViews',
                        keyInCollection: 'tables',
                    },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(MODIFY_COLUMN), payload: error });
            },
        });
    };

const reorderTables =
    (tableViewId: string, payload: any, updatedTables: TableReference[]) =>
    (dispatch: Dispatch, getState: GetState) => {
        dispatch({ type: typePending(EDIT_CONFIGURATION_TABLE) });
        return configurationShim.reorderTables(tableViewId, payload).subscribe({
            next: (_result: any) => {
                const allState = getState() as UserPortalState;
                const tableViewObject =
                    cloneDeep(allState.subscription.tableViews[tableViewId]) ?? {};

                updatedTables.forEach((x) => (tableViewObject.tables[x.id] = x));

                dispatch({
                    type: typeComplete(EDIT_CONFIGURATION_TABLE),
                    payload: null,
                });
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id: tableViewObject.id,
                        item: tableViewObject,
                        collectionName: 'tableViews',
                        type: 'PUT',
                    },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(EDIT_CONFIGURATION_TABLE), payload: error });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const deleteTableViewTable = (tableViewId: string, tableId: string) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(EDIT_CONFIGURATION_TABLE) });
    return configurationShim.deleteTableViewTable(tableViewId, tableId).subscribe({
        next: () => {
            dispatch({
                type: typeComplete(EDIT_CONFIGURATION_TABLE),
            });
            dispatch({
                type: typeComplete(CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE),
                payload: {
                    id: tableViewId,
                    collectionName: 'tableViews',
                    keyInCollection: 'tables',
                    deleteIds: [tableId],
                },
            });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(EDIT_CONFIGURATION_TABLE), payload: error });
        },
    });
};

const addConfigurationHeader =
    (
        request: ConfigurationCreateHeaderRequest,
        showSnackBar = true,
        configType = CONFIGURATION_TYPES.HEADERS,
    ) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(ADD_CONFIGURATION_HEADER), payload: request });
        return configurationShim.addConfigurationHeader(request, configType).subscribe({
            next: (response: ConfigurationHeaderResponse) => {
                dispatch({
                    type: typeComplete(ADD_CONFIGURATION_HEADER),
                    payload: response,
                });
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id: response.id,
                        item: response,
                        collectionName:
                            configType === CONFIGURATION_TYPES.HEADERS
                                ? 'headers'
                                : 'sampleDispatchHeaders',
                        type: 'PUT',
                    },
                });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: {
                        type: NotificationType.SUCCESS,
                        i18nKey:
                            configType === CONFIGURATION_TYPES.HEADERS
                                ? 'headerItemCreated'
                                : 'dispatchHeaderItemCreated',
                        i18nValues: { name: response.name },
                    },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(ADD_CONFIGURATION_HEADER), payload: { error } });
                if (showSnackBar)
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
            },
        });
    };

const editHeader =
    (id: string | undefined, payload: any, configType = CONFIGURATION_TYPES.HEADERS) =>
    (dispatch: Dispatch, getState: Function) => {
        if (!id) {
            return;
        }
        dispatch({ type: typePending(EDIT_CONFIGURATION_HEADER) });
        return configurationShim.saveHeader(id, payload, configType).subscribe({
            next: (result: any) => {
                const allState = getState() as UserPortalState;
                const headerObject = allState.subscription.headers[result.id] ?? {};
                dispatch({
                    type: typeComplete(EDIT_CONFIGURATION_HEADER),
                    payload: null,
                });
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id: result.id,
                        item: { ...headerObject, ...result },
                        collectionName:
                            configType === CONFIGURATION_TYPES.HEADERS
                                ? 'headers'
                                : 'sampleDispatchHeaders',
                        type: 'PUT',
                    },
                });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: {
                        type: NotificationType.SUCCESS,
                        i18nKey:
                            configType === CONFIGURATION_TYPES.HEADERS
                                ? 'headerEdited'
                                : 'dispatchHeaderEdited',
                        i18nValues: { name: result.name },
                    },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(EDIT_CONFIGURATION_HEADER), payload: error });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

export const deleteFieldFromHeader =
    (
        headerId: string,
        sectionId: string,
        fieldId: string,
        configType = CONFIGURATION_TYPES.HEADERS,
    ) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(EDIT_CONFIGURATION_HEADER) });
        return configurationShim
            .deleteFieldFromHeader(headerId, sectionId, fieldId, configType)
            .subscribe({
                next: (_result) => {
                    dispatch({
                        type: typeComplete(EDIT_CONFIGURATION_HEADER),
                        payload: null,
                    });
                    dispatch({
                        type: typeComplete(CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE),
                        payload: {
                            id: headerId,
                            collectionName:
                                configType === CONFIGURATION_TYPES.HEADERS
                                    ? 'headers'
                                    : 'sampleDispatchHeaders',
                            childId: sectionId,
                            childKey: 'fields',
                            keyInCollection: 'sections',
                            deleteIds: [fieldId],
                        },
                    });
                },
                error: (error: any) => {
                    dispatch({ type: typeFail(EDIT_CONFIGURATION_HEADER), payload: error });
                },
            });
    };

export const addFieldToHeaderDefaultSectionAction =
    (
        payloads: {
            headerId: string;
            sectionId: string;
            field: string;
            index: number;
            configType?: CONFIGURATION_TYPES;
        }[],
        errorDelimiter = ', ',
    ) =>
    (dispatch: Dispatch, _getState: () => BaseStateInterface) => {
        dispatch({ type: typePending(ADD_HEADER_FIELD) });
        return observableFrom(payloads)
            .pipe(
                mergeMap(
                    (payload: {
                        headerId: string;
                        sectionId: string;
                        field: string;
                        index: number;
                        configType?: CONFIGURATION_TYPES;
                    }) =>
                        configurationShim
                            .addFieldToHeaderDefaultSection(payload)
                            .pipe(catchError((error) => observableOf(error))),
                    1,
                ),
            )
            .pipe(toArray())
            .subscribe({
                next: (result: any[]) => {
                    const { allErrors, allValidResponses } = getErrorsFromResponse(
                        result,
                        'field',
                        undefined,
                        errorDelimiter,
                    );
                    if (allErrors.length > 0) {
                        const accumulatedMessage = `${allErrors.map((e) => e.message).join(errorDelimiter)}`;

                        // Add the ids of the grids that could not be removed or added as part of the error state in order to revert
                        // their selection state in Select From List page
                        dispatch({
                            type: typeFail(ADD_HEADER_FIELD),
                            payload: {
                                error: {
                                    message: accumulatedMessage,
                                    failedIds: allErrors.map((e) => e.referenceExisting),
                                },
                            },
                        });
                    } else {
                        const [sampleDispatchHeaders, headers] = partition(
                            payloads,
                            (x) => x.configType === CONFIGURATION_TYPES.SAMPLE_HEADERS,
                        );
                        if (sampleDispatchHeaders.length > 0) {
                            dispatch({
                                type: typeComplete(
                                    CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE,
                                ),
                                payload: {
                                    id: payloads[0].headerId,
                                    collectionName: 'sampleDispatchHeaders',
                                    items: allValidResponses.filter((x) =>
                                        sampleDispatchHeaders.some((y) => y.field === x.field),
                                    ),
                                    keyInCollection: 'sections',
                                    childKey: 'fields',
                                    childId: payloads[0].sectionId,
                                },
                            });
                        }
                        if (headers.length > 0) {
                            dispatch({
                                type: typeComplete(
                                    CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE,
                                ),
                                payload: {
                                    id: payloads[0].headerId,
                                    collectionName: 'headers',
                                    items: allValidResponses.filter((x) =>
                                        headers.some((y) => y.field === x.field),
                                    ),
                                    keyInCollection: 'sections',
                                    childKey: 'fields',
                                    childId: payloads[0].sectionId,
                                },
                            });
                        }
                        dispatch({ type: typeComplete(ADD_HEADER_FIELD) });
                    }
                },
            });
    };

export const deleteSectionFromHeader =
    (headerId: string, sectionId: string, configType = CONFIGURATION_TYPES.HEADERS) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(EDIT_CONFIGURATION_HEADER) });
        return configurationShim
            .deleteConfigurationHeaderSection(headerId, sectionId, configType)
            .subscribe({
                next: (result: any) => {
                    dispatch({
                        type: typeComplete(EDIT_CONFIGURATION_HEADER),
                        payload: null,
                    });
                    dispatch({
                        type: typeComplete(
                            CREATE_UPDATE_DELETE_KEY_IN_COLLECTION_ITEM_SUBSCRIPTION_STATE,
                        ),
                        payload: {
                            id: headerId,
                            collectionName:
                                configType === CONFIGURATION_TYPES.HEADERS
                                    ? 'headers'
                                    : 'sampleDispatchHeaders',
                            item: result,
                            keyInCollection: 'sections',
                        },
                    });
                },
                error: (error: any) => {
                    dispatch({ type: typeFail(EDIT_CONFIGURATION_HEADER), payload: error });
                },
            });
    };

export const swapHeaderSection =
    (header: Header, sectionIds: string[], configType = CONFIGURATION_TYPES.HEADERS) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(EDIT_CONFIGURATION_HEADER) });
        return observableFrom(
            sectionIds.map((x) => ({
                action: configurationShim.swapHeaderSections,
                params: [convertToSnake({ ...header.sections[x] }), header.id, x, configType],
            })),
        )
            .pipe(
                concatMap((parameters: { action: Function; params: any[] }) =>
                    parameters.action(...parameters.params),
                ),
            )
            .pipe(toArray())
            .subscribe({
                next: (collection: unknown[]) => {
                    // Keep calls concurrent to ensure correct section order.

                    sectionIds.forEach(
                        (x, idx) => (header.sections[x] = collection[idx] as Section),
                    );

                    dispatch({
                        type: typeComplete(EDIT_CONFIGURATION_HEADER),
                        payload: null,
                    });
                    dispatch({
                        type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                        payload: {
                            id: header.id,
                            item: header,
                            collectionName:
                                configType === CONFIGURATION_TYPES.HEADERS
                                    ? 'headers'
                                    : 'sampleDispatchHeaders',
                            type: 'PUT',
                        },
                    });
                },
                error: (error: any) => {
                    dispatch({ type: typeFail(EDIT_CONFIGURATION_HEADER), payload: error });
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                },
            });
    };

export const addHeaderSection =
    (payload: any, headerId: string, configType = CONFIGURATION_TYPES.HEADERS) =>
    (dispatch: Dispatch, getState: Function) => {
        dispatch({ type: typePending(EDIT_CONFIGURATION_HEADER) });
        return configurationShim.addHeaderSection(payload, headerId, configType).subscribe({
            next: ({ header, newSectionId }) => {
                const allState = getState() as UserPortalState;
                const headerObject = allState.subscription.headers[header.id] ?? {};
                dispatch({
                    type: typeComplete(EDIT_CONFIGURATION_HEADER),
                    payload: { id: newSectionId },
                });
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id: header.id,
                        item: { ...headerObject, ...header },
                        collectionName:
                            configType === CONFIGURATION_TYPES.HEADERS
                                ? 'headers'
                                : 'sampleDispatchHeaders',
                        type: 'PUT',
                    },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(EDIT_CONFIGURATION_HEADER), payload: error });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

export const modifyHeaderSection =
    (payload: any, headerId: string, sectionId: string, configType = CONFIGURATION_TYPES.HEADERS) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(EDIT_CONFIGURATION_HEADER) });
        return configurationShim
            .modifyHeaderSection(payload, headerId, sectionId, configType)
            .subscribe({
                next: (result: Section) => {
                    dispatch({
                        type: typeComplete(EDIT_CONFIGURATION_HEADER),
                        payload: null,
                    });
                    dispatch({
                        type: typeComplete(CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE),
                        payload: {
                            id: headerId,
                            collectionName:
                                configType === CONFIGURATION_TYPES.HEADERS
                                    ? 'headers'
                                    : 'sampleDispatchHeaders',
                            item: result,
                            keyInCollection: 'sections',
                        },
                    });
                },
                error: (error: any) => {
                    dispatch({ type: typeFail(EDIT_CONFIGURATION_HEADER), payload: error });
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                },
            });
    };

export const updateHeaderSections =
    (id: string, payload: any, configType = CONFIGURATION_TYPES.HEADERS) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(EDIT_CONFIGURATION_HEADER) });
        return configurationShim.saveHeader(id, payload, configType).subscribe({
            next: (result: Header) => {
                dispatch({
                    type: typeComplete(EDIT_CONFIGURATION_HEADER),
                    payload: null,
                });
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id: result.id,
                        item: result,
                        collectionName:
                            configType === CONFIGURATION_TYPES.HEADERS
                                ? 'headers'
                                : 'sampleDispatchHeaders',
                        type: 'PUT',
                    },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(EDIT_CONFIGURATION_HEADER), payload: error });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

export const moveFieldWithinHeaderSection =
    (
        headerId: string,
        sectionId: string,
        newFields: Field[],
        payload: any,
        configType = CONFIGURATION_TYPES.HEADERS,
    ) =>
    (dispatch: Dispatch, getState: Function) => {
        dispatch({ type: typePending(EDIT_CONFIGURATION_HEADER) });
        return configurationShim
            .reorderHeaderFields(headerId, [sectionId], payload, false, configType)
            .subscribe({
                next: (_result: any) => {
                    const allState = getState() as UserPortalState;
                    const headerObject =
                        cloneDeep(
                            allState.subscription.headers[headerId] ??
                                allState.subscription.sampleDispatchHeaders[headerId],
                        ) ?? {};

                    newFields.forEach((x) => (headerObject.sections[sectionId].fields[x.id] = x));

                    dispatch({
                        type: typeComplete(EDIT_CONFIGURATION_HEADER),
                        payload: null,
                    });
                    dispatch({
                        type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                        payload: {
                            id: headerObject.id,
                            item: headerObject,
                            collectionName:
                                configType === CONFIGURATION_TYPES.HEADERS
                                    ? 'headers'
                                    : 'sampleDispatchHeaders',
                            type: 'PUT',
                        },
                    });
                },
                error: (error: any) => {
                    dispatch({ type: typeFail(EDIT_CONFIGURATION_HEADER), payload: error });
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                },
            });
    };

const reorderCoordinatesTable =
    (header: Header, payload: any, dispatchPending = true) =>
    (dispatch: Dispatch) => {
        if (dispatchPending) {
            dispatch({ type: typePending(EDIT_CONFIGURATION_HEADER) });
        }
        return configurationShim
            .genericReorder(payload, [header.id], CONFIGURATION_TYPES.GRIDS)
            .subscribe({
                next: (result: any) => {
                    header.coordinatesTable.columns = result;

                    dispatch({
                        type: typeComplete(EDIT_CONFIGURATION_HEADER),
                        payload: null,
                    });

                    dispatch({ type: typeComplete(LOAD_HEADERS), payload: { result: [header] } });
                },
                error: (error: any) => {
                    dispatch({ type: typeFail(EDIT_CONFIGURATION_HEADER), payload: error });
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                },
            });
    };

const deleteCoordinatesTableColumn = (header: Header, columnId: string) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(EDIT_CONFIGURATION_HEADER) });
    return configurationShim
        .deleteColumn(CONFIGURATION_TYPES.HEADERS, header.id, columnId)
        .subscribe({
            next: (_result: any) => {
                const cloneHeader = cloneDeep(header);

                delete cloneHeader.coordinatesTable.columns[columnId];

                const { payload } = sortAndReIndex(cloneHeader.coordinatesTable.columns);

                reorderCoordinatesTable(cloneHeader, payload, false)(dispatch);
            },
            error: (error: any) =>
                dispatch({ type: typeFail(EDIT_CONFIGURATION_HEADER), payload: error }),
        });
};

export const moveFieldBetweenHeaderSections =
    (
        headerId: string,
        sectionIds: string[],
        prevFields: Field[],
        nextFields: Field[],
        updatePayload: any,
        reorderPayload: any,
        configType = CONFIGURATION_TYPES.HEADERS,
    ) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(EDIT_CONFIGURATION_HEADER) });
        return observableFrom([
            {
                action: configurationShim.saveHeaderField,
                params: [headerId, sectionIds[0], updatePayload, configType],
            },
            {
                action: configurationShim.reorderHeaderFields,
                params: [headerId, sectionIds, reorderPayload, true, configType],
            },
        ])
            .pipe(
                concatMap((parameters: { action: Function; params: any[] }) =>
                    parameters.action(...parameters.params),
                ),
            )
            .pipe(toArray())
            .subscribe({
                next: (collection: any[]) => {
                    // Keep calls concurrent to ensure we know where header response is.
                    const header: Header = collection[0];

                    nextFields.forEach((x) => (header.sections[sectionIds[0]].fields[x.id] = x));
                    prevFields.forEach((x) => (header.sections[sectionIds[1]].fields[x.id] = x));

                    dispatch({
                        type: typeComplete(EDIT_CONFIGURATION_HEADER),
                        payload: null,
                    });
                    dispatch({
                        type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                        payload: {
                            id: header.id,
                            item: header,
                            collectionName:
                                configType === CONFIGURATION_TYPES.HEADERS
                                    ? 'headers'
                                    : 'sampleDispatchHeaders',
                            type: 'PUT',
                        },
                    });
                },
                error: (error: any) => {
                    dispatch({ type: typeFail(EDIT_CONFIGURATION_HEADER), payload: error });
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                },
            });
    };

const importList = (file: File, listId: string, dateFormat?: string) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(IMPORT_LIST) });
    return configurationShim.importList(file, listId, dateFormat).subscribe({
        next: (response: any) => {
            dispatch({
                type: typeComplete(IMPORT_LIST),
                payload: { collection: [response.result] },
            });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(IMPORT_LIST), payload: { error } });
        },
    });
};

const bulkValidateImportList = (payloads: any[]) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(IMPORT_LIST) });
    return observableFrom(payloads)
        .pipe(
            concatMap((payload: any) => {
                const { id: _id, ...rest } = payload;
                return configurationShim.bulkValidateImportList(rest);
            }),
        )
        .pipe(toArray())
        .subscribe({
            next: (collection: BulkValidateImportList[]) => {
                dispatch({
                    type: typeComplete(IMPORT_LIST),
                    payload: {
                        collection: collection.map((x, idx) => ({
                            id: payloads[idx].id,
                            errors: x.errors,
                            infos: x.newColumns.map((x) => ({
                                name: x.name,
                                type: x.type || 'text',
                            })),
                            list: null,
                        })),
                    },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(IMPORT_LIST), payload: { error } });
            },
        });
};

const bulkImportList = (payloads: any[]) => (dispatch: Dispatch, navigate: NavigateFunction) => {
    dispatch({ type: typePending(IMPORT_LIST) });
    return observableFrom(payloads)
        .pipe(
            concatMap((payload: any) => {
                const { id: _id, ...rest } = payload;
                return configurationShim.bulkImportList(rest);
            }),
        )
        .pipe(toArray())
        .subscribe({
            next: (collection: any) => {
                (async function iffe() {
                    const allImportsSuccess = collection.every((x: any) => !!x?.success);
                    const items = collection.filter((x: any) => !!x?.list).map((y: any) => y.list);

                    if (items.length) {
                        dispatch({
                            type: typeComplete(
                                CREATE_UPDATE_DELETE_COLLECTION_ITEM_LIST_SUBSCRIPTION_STATE,
                            ),
                            payload: {
                                items,
                                collectionName: 'lists',
                                type: 'PUT',
                            },
                        });
                    }

                    if (allImportsSuccess) {
                        dispatch({
                            type: ADD_SNACKBAR_NOTIFICATION,
                            payload: {
                                type: NotificationType.SUCCESS,
                                i18nKey:
                                    collection.length > 1
                                        ? 'multiBulkImportSuccess'
                                        : 'singleBulkImportSuccess',
                                i18nValues: {
                                    count: collection.length,
                                },
                            },
                        });
                        navigate(CONFIGURATION_LISTS_PATH);
                    } else {
                        const successes = collection.filter((x: any) => x?.ok === undefined);
                        const failures = collection.filter((x: any) => x?.ok === false);
                        let payload;
                        payload = {
                            collection: successes.map((x: any, idx: number) => ({
                                ...(x ?? {
                                    errors: [],
                                    infos: [],
                                    success: false,
                                    verifiedWarnings: true,
                                    list: null,
                                }),
                                id: payloads[idx].id,
                            })),
                            override: true,
                        };

                        const failureData = await generateBulkImportPayload(failures, payloads);

                        payload = {
                            ...payload,
                            collection: [...payload.collection, ...failureData],
                        };

                        dispatch({
                            type: typeComplete(IMPORT_LIST),
                            payload,
                        });
                    }
                })();
            },
            error: (error: any) => {
                dispatch({ type: typeFail(IMPORT_LIST), payload: { error } });
            },
        });
};

const confirmImportColumnTypes = (infos: any[], id: string) => (dispatch: Dispatch) => {
    dispatch({ type: CONFIRM_COLUMN_TYPES, payload: { infos, id } });
};

const deleteImportedList = (id: string[]) => (dispatch: Dispatch) => {
    dispatch({ type: DELETE_IMPORTED_LIST, payload: id });
};

const clearConfigurationListsImportListState = () => (dispatch: Dispatch) =>
    dispatch({ type: typeComplete(CLEAR_IMPORT_LIST_STATE) });

const configurationExport = (payload: any, type: CONFIGURATION_TYPES) => (dispatch: Dispatch) => {
    const shimMap: Record<string, any> = {
        [CONFIGURATION_TYPES.LISTS]: configurationShim.exportConfigurationLists,
        [CONFIGURATION_TYPES.SAMPLE_LISTS]: configurationShim.exportConfigurationLists,
        [CONFIGURATION_TYPES.TABLES]: configurationShim.exportConfigurationTables,
    };

    dispatch({ type: typePending(EXPORT_CONFIGURATION), payload: { type } });
    return shimMap[type](payload).subscribe({
        next: (response: any) => {
            dispatch({ type: typeComplete(EXPORT_CONFIGURATION), payload: { response, type } });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(EXPORT_CONFIGURATION), payload: { error, type } });
        },
    });
};
const clearConfigurationExportState = (type: CONFIGURATION_TYPES) => (dispatch: Dispatch) =>
    dispatch({ type: typeComplete(CLEAR_EXPORT_STATE), payload: { type } });

const importTable = (additionalHeaders: Record<string, string>) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(IMPORT_TABLE) });
    return configurationShim.importTableSchema(additionalHeaders).subscribe({
        next: (result) => {
            if (result?.ok === false) {
                result.json().then(
                    (data: any) => {
                        if (data?.error) {
                            const formattedError = {
                                error: {
                                    message: data.error,
                                },
                            };
                            dispatch({
                                type: typeFail(IMPORT_TABLE),
                                payload: { error: formattedError },
                            });
                        } else {
                            dispatch({
                                type: typeComplete(IMPORT_TABLE),
                                payload: { result: data },
                            });
                        }
                    },
                    () => {
                        dispatch({ type: typeFail(IMPORT_TABLE), payload: { error: result } });
                    },
                );
            } else {
                dispatch({ type: typeComplete(IMPORT_TABLE), payload: { result } });
            }
        },
        error: (error: any) => {
            dispatch({ type: typeFail(IMPORT_TABLE), payload: { error } });
        },
    });
};

const clearConfigurationTablesImportTableState = () => (dispatch: Dispatch) =>
    dispatch({ type: typeComplete(CLEAR_IMPORT_TABLE_STATE) });

const loadConfigurationActivities = () => (dispatch: Dispatch, getState: GetState) => {
    const userId = getState().user?.id ?? '';
    const subscriptionId = getState().user?.selected?.id;
    const filters = getFilters(CONFIGURATION_TYPES.ACTIVITIES, userId, subscriptionId ?? '');
    dispatch({ type: typePending(LOAD_CONFIGURATION_ACTIVITIES) });
    return configurationShim.loadConfigurationActivities(filters).subscribe({
        next: (configurationActivitiesResponse: { items: ConfigurationList[] }) =>
            dispatch({
                type: typeComplete(LOAD_CONFIGURATION_ACTIVITIES),
                payload: configurationActivitiesResponse,
            }),
        error: (error: Error) => {
            dispatch({ type: typeFail(LOAD_CONFIGURATION_ACTIVITIES), payload: { error } });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const loadConfigurationGrids = () => (dispatch: Dispatch, getState: GetState) => {
    const allState = getState() as UserPortalState;
    const userId = allState.user?.id ?? '';
    const subscriptionId = allState.user?.selected?.id;
    const filters = getFilters(CONFIGURATION_TYPES.GRIDS, userId, subscriptionId ?? '');
    dispatch({ type: typePending(LOAD_CONFIGURATION_GRIDS) });
    return configurationShim
        .loadConfigurationGrids(filters.filter((x) => x.field !== 'isGeo'))
        .subscribe({
            next: (response: { items: GridEntry[] }) => {
                const modifiedResponse = response;
                const isGeoFilter = filters.find((x) => x.field === 'isGeo');
                const projections =
                    getState().configuration?.gridsState.projectionState.projections;

                if (isGeoFilter) {
                    modifiedResponse.items = modifiedResponse.items.filter((x) => {
                        const isGeo = projections?.[x.referencesExisting]?.isGeo;

                        return isGeoFilter.operator === 'has_values' ? !!isGeo : !isGeo;
                    });
                }
                removeNotFetchedItemsFromSelectClearState(response.items, allState, dispatch);
                dispatch({
                    type: typeComplete(LOAD_CONFIGURATION_GRIDS),
                    payload: modifiedResponse,
                });
            },
            error: (error: Error) => {
                dispatch({ type: typeFail(LOAD_CONFIGURATION_GRIDS), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
};

const loadConfigurationRankedColumns = (activityId: string) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_CONFIGURATION_RANKED_COLUMNS) });
    return loadRankedColumns(
        activityId,
        'dummy_action',
        () => {},
        ACTIVITY_TYPES.DRILLING,
    ).subscribe({
        next: (response: any) =>
            dispatch({
                type: typeComplete(LOAD_CONFIGURATION_RANKED_COLUMNS),
                payload: response,
            }),
        error: (error: Error) => {
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
            dispatch({
                type: typeFail(LOAD_CONFIGURATION_RANKED_COLUMNS),
                payload: { error },
            });
        },
    });
};

const loadPossibleSampleResultsColumns = (activityId: string) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_POSSIBLE_SAMPLE_RESULTS_COLUMNS) });
    return configurationShim.loadPossibleSampleResultsColumns(activityId).subscribe({
        next: (response: any) =>
            dispatch({
                type: typeComplete(LOAD_POSSIBLE_SAMPLE_RESULTS_COLUMNS),
                payload: response,
            }),
        error: (error: Error) => {
            dispatch({
                type: typeFail(LOAD_POSSIBLE_SAMPLE_RESULTS_COLUMNS),
                payload: { error },
            });
        },
    });
};

const clearRankedColumnsState = () => (dispatch: Dispatch) => {
    dispatch({ type: typeComplete(CLEAR_RANKED_COLUMNS_STATE) });
};

const clearPossibleColumns = () => (dispatch: Dispatch) => {
    dispatch({ type: typeComplete(CLEAR_POSSIBLE_COLUMNS) });
};

const addSampleResultsRankedColumn =
    (activityId: string, rankedColumn: RankedColumnType) => (dispatch: Dispatch) => {
        dispatch({ type: typePending(ADD_SAMPLE_RESULTS_RANKED_COLUMN) });
        return configurationShim.addSampleResultsRankedColumn(activityId, rankedColumn).subscribe({
            next: (response: { activity: ActivityMap }) => {
                const { activity } = response;
                dispatch({
                    type: typeComplete(ADD_SAMPLE_RESULTS_RANKED_COLUMN),
                    payload: response,
                });
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id: activity.id,
                        item: activity,
                        collectionName: 'activities',
                        type: 'PUT',
                    },
                });
            },
            error: (error: Error) => {
                dispatch({
                    type: typeFail(ADD_SAMPLE_RESULTS_RANKED_COLUMN),
                    payload: { error },
                });
            },
        });
    };

// Don't need to fire pending, fail, complete actions we just need the error from state
const recomputeSampleResults = (activityId: string) => (dispatch: Dispatch) =>
    configurationShim.recomputeSampleResults(activityId).subscribe({
        next: () => {},
        error: (error: Error) => {
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });

// Don't need to fire pending, fail, complete actions we just need the error from state
const updateActualDepth = (activityId: string) => (dispatch: Dispatch) =>
    configurationShim.updateActualDepth(activityId).subscribe({
        next: () => {},
        error: (error: Error) => {
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });

const editRankedColumn =
    (activityId: string, rankedColumnId: string, rankedColumn: RankedColumnType) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(EDIT_RANKED_COLUMN) });
        return configurationShim
            .editRankedColumn(activityId, rankedColumnId, rankedColumn)
            .subscribe({
                next: (response: { activity: ActivityMap }) => {
                    const { activity } = response;
                    dispatch({
                        type: typeComplete(EDIT_RANKED_COLUMN),
                        payload: response,
                    });
                    dispatch({
                        type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                        payload: {
                            id: activity.id,
                            item: activity,
                            collectionName: 'activities',
                            type: 'PUT',
                        },
                    });
                },
                error: (error: Error) => {
                    dispatch({
                        type: typeFail(EDIT_RANKED_COLUMN),
                        payload: { error },
                    });
                },
            });
    };

const deleteRankedColumn = (activityId: string, rankedColumnId: string) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(DELETE_RANKED_COLUMN) });
    return configurationShim.deleteRankedColumn(activityId, rankedColumnId).subscribe({
        next: (response: { activity: ActivityMap }) => {
            const { activity } = response;
            dispatch({
                type: typeComplete(DELETE_RANKED_COLUMN),
                payload: response,
            });
            dispatch({
                type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                payload: {
                    id: activity.id,
                    item: activity,
                    collectionName: 'activities',
                    type: 'PUT',
                },
            });
        },
        error: (error: Error) => {
            dispatch({
                type: typeFail(DELETE_RANKED_COLUMN),
                payload: { error },
            });
        },
    });
};

const deleteConfigurationActivity =
    (id: string) => (dispatch: Dispatch, getState: () => UserPortalState) => {
        dispatch({ type: typePending(DELETE_CONFIGURATION_ACTIVITY), payload: id });
        return configurationShim.deleteConfigurationActivity(id).subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(DELETE_CONFIGURATION_ACTIVITY), payload: response });
                const allState = getState();
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: { id, item: { id }, collectionName: 'activities', type: 'DELETE' },
                });
                dispatchUpdateSelectClear([{ id }], allState, dispatch, true);
            },
            error: (error: any) => {
                dispatch({ type: typeFail(DELETE_CONFIGURATION_ACTIVITY), payload: { error } });
            },
        });
    };

const reorderActivityTables =
    (activity: ActivityMap, payload: Record<string, number>, isPending = true) =>
    (dispatch: Dispatch) => {
        if (isPending) {
            dispatch({ type: typePending(EDIT_CONFIGURATION_ACTIVITY) });
        }
        return configurationShim.reorderActivityTables(activity.id, payload).subscribe({
            next: (_result: any) => {
                Object.entries(payload).forEach(([key, value]) => {
                    if (
                        activity.lithologyListSpecs &&
                        activity.lithologyListSpecs?.tableView === key
                    ) {
                        activity.lithologyListSpecs.index = value;
                    } else if (activity.samples && activity.samplesListSpecs?.tableView === key) {
                        activity.samplesListSpecs.index = value;
                    } else if (
                        activity.surveyListSpecs &&
                        activity.surveyListSpecs?.tableView === key
                    ) {
                        activity.surveyListSpecs.index = value;
                    } else {
                        activity.tableViewsSpecs[key].index = value;
                    }
                });

                dispatch({
                    type: typeComplete(EDIT_CONFIGURATION_ACTIVITY),
                    payload: null,
                });
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id: activity.id,
                        item: activity,
                        collectionName: 'activities',
                        type: 'PUT',
                    },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(EDIT_CONFIGURATION_ACTIVITY), payload: error });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const addConfigurationActivity =
    (payload: ConfigurationCreateActivityRequest) => (dispatch: Dispatch) => {
        dispatch({ type: typePending(ADD_CONFIGURATION_ACTIVITY) });
        return configurationShim.addConfigurationActivity(payload).subscribe({
            next: (item: ActivityMap) => {
                dispatch({
                    type: typeComplete(ADD_CONFIGURATION_ACTIVITY),
                    payload: item,
                });
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: { id: item.id, item, collectionName: 'activities', type: 'PUT' },
                });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: {
                        type: NotificationType.SUCCESS,
                        i18nKey: 'activityItemCreated',
                        i18nValues: { name: item.name },
                    },
                });
            },
            error: (error: Error) => {
                dispatch({ type: typeFail(ADD_CONFIGURATION_ACTIVITY), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const editConfigurationActivity =
    (
        id: string | undefined,
        payload: ActivityMap,
        successSnackbar = true,
        errorSnackbar = true,
        resetFunction = (_param: any, _panelProp?: boolean) => {},
    ) =>
    (dispatch: Dispatch) => {
        if (!id) {
            return;
        }
        dispatch({ type: typePending(EDIT_CONFIGURATION_ACTIVITY) });
        return configurationShim.modifyConfigurationActivity(id, payload).subscribe({
            next: (item: ActivityMap) => {
                resetFunction(
                    { id: '', type: ModuleTypes.UNKNOWN, itemType: ItemTypes.UNKNOWN },
                    false,
                );

                dispatch({
                    type: typeComplete(EDIT_CONFIGURATION_ACTIVITY),
                    payload: item,
                });
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: { id: item.id, item, collectionName: 'activities', type: 'PUT' },
                });
                if (successSnackbar) {
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: {
                            type: NotificationType.SUCCESS,
                            i18nKey: 'activityEdited',
                            i18nValues: { name: item.name },
                        },
                    });
                }
            },
            error: (error: Error) => {
                dispatch({ type: typeFail(EDIT_CONFIGURATION_ACTIVITY), payload: { error } });
                if (errorSnackbar) {
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                    setTimeout(() => dispatch({ type: CLEAR_CONFIGURATION_OPS_STATE })); // On the next cycle clear configuration operation state. This gives time for the components subscribing to the failed selector to do what they need to do.
                }
            },
        });
    };

const modifyAndReorderConfigurationActivity =
    (
        activity: ActivityMap,
        data: { id: string; type: ModuleTypes; itemType: ItemTypes[] },
        reorderPayload: any,
        setSelectedItem: Function,
        isDelete?: boolean,
    ) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(EDIT_CONFIGURATION_ACTIVITY) });
        const isOtherTables =
            typeof data.itemType === 'string'
                ? data.itemType === ItemTypes.OTHER_TABLES
                : data.itemType.includes(ItemTypes.OTHER_TABLES);
        const shimParams: { shim: Function; params: any[] } = isOtherTables
            ? {
                  shim: configurationShim.deleteConfigurationActivityTable,
                  params: [activity.id, data.id],
              }
            : {
                  shim: configurationShim.modifyConfigurationActivity,
                  params: [activity.id, activity],
              };

        return shimParams.shim(...shimParams.params).subscribe({
            next: (item: any) => {
                if (setSelectedItem) {
                    setSelectedItem(
                        isDelete
                            ? { id: '', type: ItemTypes.UNKNOWN }
                            : { ...data, itemType: data.itemType.join('-') },
                    );
                }

                reorderActivityTables(
                    !isOtherTables ? item : activity,
                    reorderPayload,
                    false,
                )(dispatch);
            },
            error: (error: Error) => {
                dispatch({ type: typeFail(EDIT_CONFIGURATION_ACTIVITY), payload: { error } });
            },
        });
    };

export const multiSaveTableViewSpecs =
    (activity: ActivityMap, reorderPayload: Record<string, number>, createPayloads: any[]) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(EDIT_CONFIGURATION_ACTIVITY) });
        return observableFrom(createPayloads)
            .pipe(
                mergeMap(
                    (payload: any) =>
                        configurationShim
                            .addConfigurationActivityTable(activity.id, convertToSnake(payload))
                            .pipe(catchError((error) => observableOf(error))),
                    1,
                ),
            )
            .pipe(toArray())
            .subscribe({
                next: (collection: any[]) => {
                    const { allErrors, allValidResponses } = getErrorsFromResponse(
                        collection,
                        'tableView',
                        dispatch,
                    );
                    if (allValidResponses.length > 0) {
                        allValidResponses.forEach((x) => {
                            activity.tableViewsSpecs[x.tableView] = x;
                        });
                        reorderActivityTables(activity, reorderPayload, false)(dispatch);
                    }
                    if (allErrors.length > 0) {
                        subscriptionState.actions.loadAccountActivity(activity.id)(
                            dispatch,
                            getState,
                        );
                        dispatch({
                            type: typeComplete(EDIT_CONFIGURATION_ACTIVITY),
                            payload: null,
                        });
                    }
                },
            });
    };

export const modifyConfigurationActivityListSpecs =
    (
        activity: ActivityMap,
        action: ListSpecAction,
        listSpec: ListSpec,
        listSpecParams: any[],
        payload: any[],
    ) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(EDIT_CONFIGURATION_ACTIVITY) });
        const convertedListSpec = convertToSnake(listSpec);
        const isGridCoordinate = Object.keys(listSpecParams[0] ?? {}).includes(
            'header_coord_specs',
        );

        const updateActivity = (
            listSpec: string,
            tableViewId: string,
            tableId: string,
            columnId: string,
            item: any,
        ) => {
            if (listSpec === 'tableViewsSpecs') {
                activity[listSpec][tableViewId].tableSpecs[tableId].listSpecs[columnId] = item;
            } else {
                activity[listSpec].tableSpecs[tableId].listSpecs[columnId] = item;
            }
        };

        const addListSpec = (listParams: [string, any][], payload: any, item: any) => {
            const [key, value] = listParams.shift() ?? ['', ''];

            if (listParams.length) {
                item = item[key];

                if (value) {
                    item = item[value];
                }

                addListSpec(listParams, payload, item);
            } else {
                item[key][value] = payload;
            }
        };

        const replaceListSpec = (listSpec: string) =>
            listSpec.replace(/(?!^)_(.)/g, (_: any, char: any) => char.toUpperCase());

        return observableFrom(action === ListSpecAction.LINK_MODIFIED ? payload : listSpecParams)
            .pipe(
                mergeMap((_observableItem: any, idx: number) => {
                    const listSpecParam = listSpecParams[idx];
                    const convertedPayload = convertToSnake(payload[idx]);

                    let shimParams: { shim: Function; params: any[] } =
                        action === ListSpecAction.LINK_MODIFIED
                            ? {
                                  shim: configurationShim.linkActivityLists,
                                  params: [activity.id, convertedPayload],
                              }
                            : {
                                  shim: configurationShim.modifyConfigurationActivityListSpec,
                                  params: [listSpecParam, convertedListSpec],
                              };

                    if (isGridCoordinate) {
                        shimParams = {
                            shim: configurationShim.modifyConfigurationActivity,
                            params: [activity.id, { header_coord_specs: convertedListSpec }],
                        };
                    }

                    return shimParams.shim(...shimParams.params);
                }, 1),
            )
            .pipe(toArray())
            .subscribe({
                next: (item: any[]) => {
                    item.forEach((x, idx) => {
                        const currentListSpecParams = listSpecParams[idx];
                        const currentPayload = payload[idx];

                        if (action === ListSpecAction.LINK_MODIFIED) {
                            const parentListSpec = replaceListSpec(currentPayload.parentListSpecs);
                            const childListSpec = replaceListSpec(currentPayload.childListSpecs);

                            updateActivity(
                                parentListSpec,
                                currentPayload.parentTableViewId,
                                currentPayload.parentTableId,
                                currentPayload.parentColumnId,
                                x.parentColumnSpec,
                            );
                            updateActivity(
                                childListSpec,
                                currentPayload.childTableViewId,
                                currentPayload.childTableId,
                                currentPayload.childColumnId,
                                x.childColumnSpec,
                            );
                        } else if (!isGridCoordinate) {
                            const listParamsCollection = Object.entries({
                                ...convertToCamel<Record<string, string>>(currentListSpecParams),
                            });
                            listParamsCollection.shift();

                            addListSpec(listParamsCollection, x, activity);
                        }
                    });

                    dispatch({
                        type: typeComplete(EDIT_CONFIGURATION_ACTIVITY),
                        payload: item[0],
                    });
                    dispatch({
                        type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                        payload: {
                            id: activity.id,
                            item: isGridCoordinate ? item[0] : activity,
                            collectionName: 'activities',
                            type: 'PUT',
                        },
                    });
                },
                error: (error: Error) => {
                    dispatch({ type: typeFail(EDIT_CONFIGURATION_ACTIVITY), payload: { error } });
                },
            });
    };

const modifySampleWorkflows =
    (activityId: string, sampleWorkflowIds: string[], isPrimary = true) =>
    (dispatch: Dispatch, getState: GetState) => {
        dispatch({ type: typePending(MODIFY_SAMPLE_WORKFLOWS) });
        const allState = getState() as UserPortalState;
        const activityInState = allState.subscription.activities[activityId] ?? {};
        const updatedSampling = { ...activityInState.sampling };

        return observableFrom(sampleWorkflowIds)
            .pipe(
                mergeMap(
                    (id: any) =>
                        configurationShim
                            .modifySampleWorkflows(activityId, id, isPrimary)
                            .pipe(catchError((error) => observableOf(error))),
                    1,
                ),
            )
            .pipe(toArray())
            .subscribe({
                next: (response: any[]) => {
                    dispatch({
                        type: typeComplete(MODIFY_SAMPLE_WORKFLOWS),
                        payload: { isPrimary },
                    });

                    let update: any = {};
                    if (isPrimary) {
                        updatedSampling.primaryWorkflows ??= {};
                        update = updatedSampling.primaryWorkflows;
                    } else {
                        updatedSampling.secondaryWorkflows ??= {};
                        update = updatedSampling.secondaryWorkflows;
                    }

                    const { allErrors, allValidResponses } = getErrorsFromResponse(
                        response,
                        'id',
                        dispatch,
                    );
                    if (allValidResponses.length > 0) {
                        allValidResponses.forEach((res) => {
                            update[res.id] = res;
                        });
                        dispatch({
                            type: typeComplete(
                                CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE,
                            ),
                            payload: {
                                id: activityId,
                                item: { ...activityInState, sampling: updatedSampling },
                                collectionName: 'activities',
                                type: 'PUT',
                            },
                        });
                    }
                    if (allErrors.length > 0) {
                        subscriptionState.actions.loadAccountActivity(activityId)(
                            dispatch,
                            getState,
                        );
                    }
                },
            });
    };

const modifyColumnAssociations = (activityId: string, payload: any) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(EDIT_CONFIGURATION_ACTIVITY) });
    return configurationShim.modifyColumnAssociations(activityId, payload).subscribe({
        next: (item: any) => {
            dispatch({
                type: typeComplete(EDIT_CONFIGURATION_ACTIVITY),
                payload: item,
            });
            dispatch({
                type: typeComplete(CREATE_UPDATE_DELETE_KEY_IN_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                payload: {
                    id: activityId,
                    item,
                    collectionName: 'activities',
                    keyInCollection: 'columnAssociations',
                    type: 'POST',
                },
            });
        },
        error: (error: Error) => {
            dispatch({ type: typeFail(EDIT_CONFIGURATION_ACTIVITY), payload: { error } });
        },
    });
};

const loadConfigurationProjects = () => (dispatch: Dispatch, getState: GetState) => {
    const userId = getState().user?.id ?? '';
    const subscriptionId = getState().user?.selected?.id;
    const filters = getFilters(CONFIGURATION_TYPES.PROJECTS, userId, subscriptionId ?? '');
    dispatch({ type: typePending(LOAD_CONFIGURATION_PROJECTS) });
    return configurationShim
        .loadConfigurationProjects(filters.filter((x) => x.field !== 'users'))
        .subscribe({
            next: (configurationProjectsResponse: { items: ConfigurationProject[] }) => {
                const response = configurationProjectsResponse;
                const usersFilter = filters.find((x) => x.field === 'users');

                if (usersFilter) {
                    response.items = response.items.filter((x) => {
                        const users: string[] = [];
                        Object.values(x.activities ?? {}).forEach((value) =>
                            users.push(...Object.keys(value.users)),
                        );
                        users.push(...Object.keys(x.users ?? {}));

                        let condition = false;

                        switch (usersFilter.operator) {
                            case 'one_of':
                                if (!usersFilter.values.length) {
                                    condition = !users.length;
                                } else if (usersFilter.values[0] === 'all') {
                                    condition = !!users.length;
                                } else {
                                    condition = usersFilter.values.some((x: string) =>
                                        users.includes(x),
                                    );
                                }
                                break;
                            case 'has_values':
                                condition = !!users.length;
                                break;
                            case 'empty':
                                condition = !users.length;
                                break;
                            default:
                                break;
                        }

                        return condition;
                    });
                }

                dispatch({
                    type: typeComplete(LOAD_CONFIGURATION_PROJECTS),
                    payload: configurationProjectsResponse,
                });
            },
            error: (error: Error) => {
                dispatch({ type: typeFail(LOAD_CONFIGURATION_PROJECTS), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
};

const addConfigurationProject =
    (
        request: ConfigurationCreateListRequest,
        showSnackbar = false,
        isClone = true,
        file: File | null = null,
        projectFileGroup: any = null,
    ) =>
    (dispatch: Dispatch) => {
        const disptachType = isClone ? CLONE_CONFIGURATION_PROJECT : ADD_CONFIGURATION_PROJECT;
        dispatch({ type: typePending(disptachType), payload: request });

        const successFn = (response: any, fileGroup: any = null) => {
            dispatchProjectCollections(response, dispatch, fileGroup);
            dispatch({ type: typeComplete(disptachType), payload: response });
            if (showSnackbar) {
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: {
                        type: NotificationType.SUCCESS,
                        i18nKey: 'projectCreated',
                        i18nValues: { name: response.name },
                    },
                });
            }
        };

        const errorFn = (error: any) => {
            dispatch({ type: typeFail(disptachType), payload: { error } });
            if (showSnackbar) {
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            }
        };

        return configurationShim.addConfigurationProject(request).subscribe({
            next: (response: Project) => {
                if (file) {
                    uploadProjectThumbnail(response, file, true, projectFileGroup)(dispatch);
                } else if (isClone && !!response.thumbnail) {
                    projectShim.loadProjectFileGroups(response.id).subscribe({
                        next: (fileGroups) => successFn(response, fileGroups[0]),
                        error: (error: any) => errorFn(error),
                    });
                } else {
                    successFn(response);
                }
            },
            error: (error: any) => errorFn(error),
        });
    };

const deleteConfigurationProject =
    (id: string) => (dispatch: Dispatch, getState: () => UserPortalState) => {
        dispatch({ type: typePending(DELETE_CONFIGURATION_PROJECT), payload: id });
        return configurationShim.deleteConfigurationProject(id).subscribe({
            next: (response: any) => {
                dispatch({ type: typeComplete(DELETE_CONFIGURATION_PROJECT), payload: response });
                const allState = getState();
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_PROJECT_STATE),
                    payload: { id, item: { id }, collectionName: 'allProjects', type: 'DELETE' },
                });
                dispatchUpdateSelectClear([{ id }], allState, dispatch, true);
            },
            error: (error: any) => {
                dispatch({ type: typeFail(DELETE_CONFIGURATION_PROJECT), payload: { error } });
            },
        });
    };

const editConfigurationProject =
    (payload: any, showSnackBar = true) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(EDIT_CONFIGURATION_PROJECT) });
        return configurationShim.modifyConfigurationProject(payload).subscribe({
            next: (response: Project) => {
                const projectData = { ...response, ...(payload?.pinned && { pinned: true }) };
                dispatch({
                    type: typeComplete(EDIT_CONFIGURATION_PROJECT),
                    payload: { projectData },
                });

                dispatchProjectCollections(projectData, dispatch);

                if (showSnackBar) {
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: {
                            type: NotificationType.SUCCESS,
                            i18nKey: 'projectUpdated',
                            i18nValues: { name: projectData.name },
                        },
                    });
                }
            },
            error: (error: any) => {
                dispatch({ type: typeFail(EDIT_CONFIGURATION_PROJECT), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const convertProjectBoundaries = (payload: any) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(CONVERT_PROJECT_BOUNDARIES) });
    return configurationShim.convertProjectBoundaries(payload).subscribe({
        next: (response: any) => {
            dispatch({
                type: typeComplete(CONVERT_PROJECT_BOUNDARIES),
                payload: { response },
            });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(CONVERT_PROJECT_BOUNDARIES), payload: { error } });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

function getErrorsFromResponse(
    response: any[],
    validObjectKey: string,
    dispatch?: Dispatch,
    delimiter = ', ',
) {
    const allErrors = response.filter((x) => !(validObjectKey in x));
    const allValidResponses = response.filter((x) => validObjectKey in x);
    const errorsArray: any[] = [];
    if (allErrors.length) {
        allErrors.forEach((e) => {
            const messageObject = getErrorFromState(e as AsyncStateError);
            if (!isEmpty(messageObject)) {
                errorsArray.push(messageObject.errorMessage);
            }
        });

        if (dispatch) {
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: {
                    type: NotificationType.ERROR,
                    message: { message: errorsArray.join(delimiter) },
                },
            });
        }
    }
    return { allErrors, allValidResponses, errorsArray };
}

const addConfigurationProjectActivity =
    (projectId: string, payloads: any[]) => (dispatch: Dispatch, getState: GetState) => {
        dispatch({ type: typePending(ADD_CONFIGURATION_PROJECT_ACTIVITY) });

        return observableFrom(payloads)
            .pipe(
                mergeMap((payload: any) => {
                    let shim: any = configurationShim.addConfigurationProjectActivity;
                    if (payload.type === 'edit') {
                        shim = configurationShim.modifyConfigurationProjectActivity;
                    }
                    return shim(projectId, payload).pipe(
                        catchError((error) => observableOf(error)),
                    );
                }, 1),
            )
            .pipe(toArray())
            .subscribe({
                next: (response: any) => {
                    const { allErrors, allValidResponses } = getErrorsFromResponse(
                        response,
                        'activity',
                        dispatch,
                    );
                    dispatch({
                        type: typeComplete(ADD_CONFIGURATION_PROJECT_ACTIVITY),
                        payload: { response },
                    });
                    if (allValidResponses.length > 0) {
                        updateActivitiesInProjectCollections(
                            projectId,
                            allValidResponses,
                            dispatch,
                        );
                    }
                    if (allErrors.length > 0) {
                        projectState.actions.loadProject(projectId)(dispatch, getState);
                    }
                },
            });
    };

const editConfigurationProjectActivities =
    (projectId: string, payloadArray: EditConfigurationProjectActivity[]) =>
    (dispatch: Dispatch, getState: GetState) => {
        dispatch({ type: typePending(EDIT_CONFIGURATION_PROJECT_ACTIVITIES) });
        return observableFrom(payloadArray)
            .pipe(
                mergeMap(
                    (payload: EditConfigurationProjectActivity) =>
                        configurationShim.modifyConfigurationProjectActivity(projectId, payload),
                    1,
                ),
            )
            .pipe(toArray())
            .subscribe({
                next: (results: any[]) => {
                    const allState = getState() as UserPortalState;
                    const currentProject = cloneDeep(allState.project.allProjects[projectId]);
                    const { allErrors, allValidResponses } = getErrorsFromResponse(
                        results,
                        'activity',
                        dispatch,
                    );
                    if (allValidResponses.length > 0) {
                        allValidResponses.forEach((activity: EditConfigurationProjectActivity) => {
                            if (!currentProject.activities) {
                                currentProject.activities = {};
                            }
                            currentProject.activities[activity.id] = activity;
                        });
                        dispatchProjectCollections(currentProject, dispatch);
                    }
                    if (allErrors.length > 0) {
                        projectState.actions.loadProject(projectId)(dispatch, getState);
                    }
                    dispatch({
                        type: typeComplete(EDIT_CONFIGURATION_PROJECT_ACTIVITIES),
                        payload: {},
                    });
                },
            });
    };

const deleteConfigurationProjectActivity =
    (projectId: string, activityId: string) => (dispatch: Dispatch) => {
        dispatch({ type: typePending(DELETE_CONFIGURATION_PROJECT_ACTIVITY) });
        return configurationShim
            .deleteConfigurationProjectActivity(projectId, activityId)
            .subscribe({
                next: (response: any) => {
                    dispatch({
                        type: typeComplete(DELETE_CONFIGURATION_PROJECT_ACTIVITY),
                        payload: { response },
                    });
                    updateActivitiesInProjectCollections(projectId, [response], dispatch, true);
                },
                error: (error: any) => {
                    dispatch({
                        type: typeFail(DELETE_CONFIGURATION_PROJECT_ACTIVITY),
                        payload: { error },
                    });
                    // dont want snackbar error as it is handled in the dialog
                },
            });
    };

const uploadProjectThumbnail =
    (project: Project, fileObject: any, isCreate = false, projectFileGroup: any = null) =>
    (dispatch: Dispatch) => {
        const dispatchType = isCreate ? ADD_CONFIGURATION_PROJECT : EDIT_CONFIGURATION_PROJECT;

        if (!isCreate) {
            dispatch({ type: typePending(EDIT_CONFIGURATION_PROJECT) });
        }

        const successFn = (newProject: Project, fileGroup: any = null) => {
            dispatch({
                type: typeComplete(dispatchType),
                payload: isCreate ? newProject : { projectData: newProject },
            });

            dispatchProjectCollections(newProject, dispatch, fileGroup);

            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: {
                    type: NotificationType.SUCCESS,
                    i18nKey: isCreate ? 'projectCreated' : 'projectThumbnailUpdated',
                    i18nValues: { name: newProject.name },
                },
            });
        };

        const errorFn = (error: any) => {
            dispatch({ type: typeFail(dispatchType), payload: { error } });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        };

        return (
            !projectFileGroup
                ? configurationShim.addProjectFileGroup({ project: project.id })
                : configurationShim.uploadProjectThumbnail(projectFileGroup.id, fileObject)
        ).subscribe({
            next: (response: any) => {
                if (!projectFileGroup) {
                    const newProj = cloneDeep(project);
                    newProj.thumbnail = response.id;

                    observableFrom([
                        {
                            action: configurationShim.modifyConfigurationProject as any,
                            params: [newProj],
                        },
                        {
                            action: configurationShim.uploadProjectThumbnail as any,
                            params: [response.id, fileObject],
                        },
                    ])
                        .pipe(concatMap(({ action, params }) => action(...params)))
                        .pipe(toArray())
                        .subscribe({
                            next: (concatResponse: any[]) =>
                                successFn(concatResponse[0], {
                                    ...response,
                                    files: { [concatResponse[1].id]: concatResponse[1] },
                                }),
                            error: (error: Error) => errorFn(error),
                        });
                } else {
                    successFn(project, { ...projectFileGroup, files: { [response.id]: response } });
                }
            },
            error: (error: any) => errorFn(error),
        });
    };

const deleteProjectThumbnail =
    (project: Project, projectFileGroup: ProjectFileGroup) => (dispatch: Dispatch) => {
        dispatch({ type: typePending(EDIT_CONFIGURATION_PROJECT) });
        return configurationShim
            .deleteProjectThumbnail(projectFileGroup.id, Object.keys(projectFileGroup.files)[0])
            .subscribe({
                next: () => {
                    const clonedProjFileGroup = cloneDeep(projectFileGroup);
                    clonedProjFileGroup.files = {};

                    dispatchProjectCollections(project, dispatch, clonedProjFileGroup);

                    dispatch({
                        type: typeComplete(EDIT_CONFIGURATION_PROJECT),
                        payload: { projectData: project },
                    });
                },
                error: (error: any) => {
                    dispatch({ type: typeFail(EDIT_CONFIGURATION_PROJECT), payload: { error } });
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                },
            });
    };

const loadProjectUserPermissions =
    (projectId: string, userId: string, activityId = '') =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(LOAD_USER_PERMISSIONS) });
        return configurationShim.loadUserPermissions(projectId, userId, activityId).subscribe({
            next: (response: any) => {
                dispatch({
                    type: typeComplete(LOAD_USER_PERMISSIONS),
                    payload: response.items,
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(LOAD_USER_PERMISSIONS), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const modifyProjectActivityUserPermission =
    (project: Project, payload: any, activityId: string, userId: string) =>
    (dispatch: Dispatch) => {
        const useId = userId in project.activities[activityId].users;

        dispatch({ type: typePending(EDIT_CONFIGURATION_PROJECT) });
        return configurationShim
            .modifyProjectActivityUserPermission(
                project.id,
                activityId,
                payload,
                useId ? userId : '',
            )
            .subscribe({
                next: (response: any) => {
                    const projCopy = cloneDeep(project);
                    projCopy.activities[activityId].users[userId] = response;

                    dispatchProjectCollections(projCopy, dispatch);

                    dispatch({
                        type: typeComplete(EDIT_CONFIGURATION_PROJECT),
                        payload: {},
                    });
                },
                error: (error: any) => {
                    dispatch({ type: typeFail(EDIT_CONFIGURATION_PROJECT), payload: { error } });
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                },
            });
    };

function addUserToActivityGroupUserObjects(
    currentProject: Project,
    user: ActivityUser,
    activityId?: string,
) {
    for (const key in currentProject.activities) {
        if (activityId && key !== activityId) {
            continue;
        }
        if (!currentProject.activities[key].users) {
            currentProject.activities[key].users = {};
        }

        currentProject.activities[key].users[user.id] = {
            ...user,
            fullAccess: true,
            userPermissions: {},
        };
        for (const groupKey in currentProject.activities[key].groups) {
            if (!currentProject.activities[key].groups[groupKey].users) {
                currentProject.activities[key].groups[groupKey].users = {};
            }

            currentProject.activities[key].groups[groupKey].users[user.id] = { ...user };
        }
    }
}

const assignUnassignUsers =
    (payloadArray: (UnassignPayload | AssignPayload)[], projectId: string) =>
    (dispatch: Dispatch, getState: GetState) => {
        dispatch({ type: typePending(ASSIGN_UNASSIGN_USERS) });
        return observableFrom(payloadArray)
            .pipe(
                mergeMap((payload: UnassignPayload | AssignPayload) => {
                    if (payload.payloadType === 'unassign-remove') {
                        const unassignRemovePayload = payload as UnassignPayload;
                        if (payload.type === 'activity') {
                            return configurationShim
                                .removeUserFromProjectActivity(
                                    payload.projectId,
                                    unassignRemovePayload.userId,
                                    payload.activityId ?? '',
                                )
                                .pipe(catchError((error) => observableOf(error)));
                        }
                        if (payload.type === 'group') {
                            return configurationShim
                                .removeUserFromProjectActivityGroup(
                                    payload.projectId,
                                    unassignRemovePayload.userId,
                                    payload.activityId ?? '',
                                    payload.groupId ?? '',
                                )
                                .pipe(catchError((error) => observableOf(error)));
                        }
                        return observableOf(undefined);
                    }
                    if (payload.payloadType === 'unassign') {
                        // Unassign users
                        const unassignPayload = payload as UnassignPayload;
                        if (payload.type === 'project') {
                            return configurationShim
                                .deleteUserFromProject(payload.projectId, unassignPayload.userId)
                                .pipe(catchError((error) => observableOf(error)));
                        }
                        if (payload.type === 'activity') {
                            return configurationShim
                                .deleteUserFromProjectActivity(
                                    payload.projectId,
                                    payload.activityId ?? '',
                                    unassignPayload.userId,
                                )
                                .pipe(catchError((error) => observableOf(error)));
                        }
                        if (payload.type === 'group') {
                            return configurationShim
                                .deleteUserFromProjectActivityGroup(
                                    payload.projectId,
                                    payload.activityId ?? '',
                                    payload.groupId ?? '',
                                    unassignPayload.userId,
                                )
                                .pipe(catchError((error) => observableOf(error)));
                        }
                        return observableOf(undefined);
                    }
                    // Assign users
                    const assignPayload = payload as AssignPayload;
                    if (payload.type === 'project') {
                        return configurationShim
                            .assignUserToProject(payload.projectId, assignPayload.user)
                            .pipe(catchError((error) => observableOf(error)));
                    }
                    if (payload.type === 'activity') {
                        return configurationShim
                            .assignUserToProjectActivity(
                                payload.projectId,
                                payload.activityId ?? '',
                                assignPayload.user,
                            )
                            .pipe(catchError((error) => observableOf(error)));
                    }
                    if (payload.type === 'group') {
                        return configurationShim
                            .assignUserToProjectActivityGroup(
                                payload.projectId,
                                payload.activityId ?? '',
                                payload.groupId ?? '',
                                assignPayload.user,
                            )
                            .pipe(catchError((error) => observableOf(error)));
                    }
                    return observableOf(undefined);
                }, 1),
            )
            .pipe(toArray())
            .subscribe({
                next: (
                    results: {
                        user: ActivityUser;
                        payloadType: AssignUnassignPayloadType;
                        type: AssignUnassignObjectType;
                        projectId: string;
                        activityId?: string;
                        groupId?: string;
                    }[],
                ) => {
                    const allState = getState() as UserPortalState;
                    const currentProject = cloneDeep(allState.project.allProjects[projectId]);
                    const { allErrors, allValidResponses } = getErrorsFromResponse(
                        results,
                        'user',
                        dispatch,
                    );
                    if (allValidResponses.length > 0) {
                        allValidResponses.forEach((x) => {
                            const { user, type, activityId, groupId } = x;
                            if (x.payloadType === 'unassign-remove') {
                                if (type === 'activity') {
                                    addUserToActivityGroupUserObjects(currentProject, user);
                                    delete currentProject.users[user.id];
                                    delete currentProject.activities[activityId ?? ''].users[
                                        user.id
                                    ];
                                    Object.keys(
                                        currentProject.activities[activityId ?? '']?.groups ?? {},
                                    ).forEach((groupKey) => {
                                        Object.keys(
                                            currentProject.activities[activityId ?? '']?.groups[
                                                groupKey ?? ''
                                            ]?.users ?? {},
                                        ).forEach((userId) => {
                                            if (user.id === userId) {
                                                delete currentProject.activities[activityId ?? '']
                                                    .groups[groupKey ?? ''].users[userId];
                                            }
                                        });
                                    });
                                }
                                if (x.type === 'group') {
                                    if (currentProject.users[user.id]) {
                                        addUserToActivityGroupUserObjects(currentProject, user);
                                        delete currentProject.users[user.id];
                                        currentProject.activities[activityId ?? ''].users[user.id] =
                                            { ...user, fullAccess: false, userPermissions: {} };
                                        delete currentProject.activities[activityId ?? ''].groups[
                                            groupId ?? ''
                                        ].users[user.id];
                                        // Check if user is in any other group
                                        const someOtherGroupHasUser = Object.keys(
                                            currentProject.activities[activityId ?? ''].groups,
                                        ).some(
                                            (key) =>
                                                user.id in
                                                    currentProject.activities[activityId ?? '']
                                                        .groups[key].users && key !== groupId,
                                        );
                                        // If user is not in any other group, delete from activity users
                                        if (!someOtherGroupHasUser) {
                                            delete currentProject.activities[activityId ?? '']
                                                .users[user.id];
                                        }
                                    } else if (
                                        currentProject.activities[activityId ?? '']?.users?.[
                                            user.id
                                        ]
                                    ) {
                                        addUserToActivityGroupUserObjects(
                                            currentProject,
                                            user,
                                            activityId,
                                        );
                                        currentProject.activities[activityId ?? ''].users[user.id] =
                                            { ...user, fullAccess: false, userPermissions: {} };
                                        delete currentProject.activities[activityId ?? ''].groups[
                                            groupId ?? ''
                                        ].users[user.id];
                                        const someOtherGroupHasUser = Object.keys(
                                            currentProject.activities[activityId ?? ''].groups,
                                        ).some(
                                            (key) =>
                                                user.id in
                                                    currentProject.activities[activityId ?? '']
                                                        .groups[key].users && key !== groupId,
                                        );
                                        if (!someOtherGroupHasUser) {
                                            delete currentProject.activities[activityId ?? '']
                                                .users[user.id];
                                        }
                                    }
                                }
                            } else if (x.payloadType === 'unassign') {
                                // Unassign
                                if (type === 'project') {
                                    // Delete user from project users
                                    delete currentProject.users[user.id];
                                } else if (type === 'activity' && activityId) {
                                    // Delete user from activity users
                                    delete currentProject.activities[activityId].users[user.id];
                                } else {
                                    // Delete the user from the group
                                    delete currentProject.activities[activityId ?? '']?.groups[
                                        groupId ?? ''
                                    ]?.users[user.id];
                                }
                            } else if (x.payloadType === 'assign') {
                                // Assign
                                if (type === 'project') {
                                    // Update in the root project object
                                    if (!('users' in currentProject)) {
                                        (currentProject as Project).users = {};
                                    }
                                    currentProject.users[user.id] = user;
                                } else if (type === 'activity' && activityId) {
                                    // Update in the activity object in project
                                    if (
                                        currentProject.activities[activityId] &&
                                        !('users' in currentProject.activities[activityId])
                                    ) {
                                        (currentProject.activities[activityId] as Activity).users =
                                            {};
                                    }
                                    currentProject.activities[activityId].users[user.id] = user;
                                } else {
                                    // Update in the group object in project activity
                                    if (
                                        !(
                                            'users' in
                                            currentProject.activities[activityId ?? ''].groups[
                                                groupId ?? ''
                                            ]
                                        )
                                    ) {
                                        currentProject.activities[activityId ?? ''].groups[
                                            groupId ?? ''
                                        ].users = {};
                                    }
                                    currentProject.activities[activityId ?? ''].groups[
                                        groupId ?? ''
                                    ].users[user.id] = user;
                                }
                            }
                        });
                        dispatchProjectCollections(currentProject, dispatch);
                    }
                    if (allErrors.length > 0) {
                        projectState.actions.loadProject(projectId)(dispatch, getState);
                    }
                    dispatch({
                        type: typeComplete(ASSIGN_UNASSIGN_USERS),
                        payload: {},
                    });
                },
            });
    };

const toggleShowBoundaryInDecimal = () => (dispatch: Dispatch) =>
    dispatch({ type: CONVERT_BOUNDARY_TO_DECIMAL });

const loadConfigurationSampleWorkflows = () => (dispatch: Dispatch, getState: GetState) => {
    const userId = getState().user?.id ?? '';
    const subscriptionId = getState().user?.selected?.id;
    const filters = getFilters(
        CONFIGURATION_TYPES.SAMPLE_ANALYSIS_WORKFLOWS,
        userId,
        subscriptionId ?? '',
    );
    dispatch({ type: typePending(LOAD_CONFIGURATION_SAMPLE_WORKFLOWS) });
    return configurationShim.loadConfigurationSampleWorkflows(filters).subscribe({
        next: (r: { items: SampleWorkflow[] }) => {
            dispatch({
                type: typeComplete(LOAD_CONFIGURATION_SAMPLE_WORKFLOWS),
                payload: r,
            });
        },
        error: (error: Error) => {
            dispatch({
                type: typeFail(LOAD_CONFIGURATION_SAMPLE_WORKFLOWS),
                payload: { error },
            });
        },
    });
};

const clearConfigurationSampleWorkflowState = () => (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_CONFIGURATION_SAMPLE_WORKFLOW_STATE });
};
const clearConfigurationSampleWorkflowAddState = () => (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_CONFIGURATION_SAMPLE_WORKFLOW_ADD_STATE });
};

export const addCertificateImport =
    (type: CertificateImportTypes, workflowId: string, payload: any) => (dispatch: Dispatch) => {
        dispatch({ type: typePending(EDIT_CONFIGURATION_SAMPLE_WORKFLOW) });
        return configurationShim.addCertificateImport(type, workflowId, payload).subscribe({
            next: (response) => {
                dispatch({ type: typeComplete(EDIT_CONFIGURATION_SAMPLE_WORKFLOW) });
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id: workflowId,
                        collectionName: 'sampleWorkflows',
                        keyInCollection:
                            type === WorkflowTypes.VALIDATION_RULES
                                ? 'labCertificateValidations'
                                : 'labCertificateSettings',
                        item: response,
                    },
                });
            },
            error: (error: any) => {
                dispatch({
                    type: typeFail(EDIT_CONFIGURATION_SAMPLE_WORKFLOW),
                    payload: { error },
                });
            },
        });
    };

const deleteCertificateImport =
    (type: CertificateImportTypes, workflowId: string, itemId: string) => (dispatch: Dispatch) => {
        dispatch({ type: typePending(EDIT_CONFIGURATION_SAMPLE_WORKFLOW), payload: workflowId });
        return configurationShim.deleteCertificateImport(type, workflowId, itemId).subscribe({
            next: (response: any) => {
                dispatch({
                    type: typeComplete(EDIT_CONFIGURATION_SAMPLE_WORKFLOW),
                    payload: response,
                });
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id: workflowId,
                        collectionName: 'sampleWorkflows',
                        keyInCollection:
                            type === WorkflowTypes.VALIDATION_RULES
                                ? 'labCertificateValidations'
                                : 'labCertificateSettings',
                        deleteIds: [itemId],
                    },
                });
            },
            error: (error: any) => {
                dispatch({
                    type: typeFail(EDIT_CONFIGURATION_SAMPLE_WORKFLOW),
                    payload: { error },
                });
            },
        });
    };

const editCertificateImport =
    (type: CertificateImportTypes, workflowId: string, itemId: string, payload: any) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(EDIT_CONFIGURATION_SAMPLE_WORKFLOW) });
        return configurationShim
            .editCertificateImport(type, workflowId, itemId, payload)
            .subscribe({
                next: (result: unknown) => {
                    const item = result as LabCertificateValidation;
                    dispatch({
                        type: typeComplete(EDIT_CONFIGURATION_SAMPLE_WORKFLOW),
                        payload: item,
                    });
                    dispatch({
                        type: typeComplete(CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE),
                        payload: {
                            id: workflowId,
                            collectionName: 'sampleWorkflows',
                            keyInCollection:
                                type === WorkflowTypes.VALIDATION_RULES
                                    ? 'labCertificateValidations'
                                    : 'labCertificateSettings',
                            item,
                        },
                    });
                },
                error: (error: any) => {
                    dispatch({
                        type: typeFail(EDIT_CONFIGURATION_SAMPLE_WORKFLOW),
                        payload: { error },
                    });
                },
            });
    };

const addConfigurationSampleWorkflow =
    (payload: Partial<ConfigurationCreateWorkflowRequest>) => (dispatch: Dispatch) => {
        dispatch({ type: typePending(ADD_CONFIGURATION_SAMPLE_WORKFLOW) });
        return configurationShim.addConfigurationSampleWorkflow(payload).subscribe({
            next: (item: SampleWorkflow) => {
                dispatch({
                    type: typeComplete(ADD_CONFIGURATION_SAMPLE_WORKFLOW),
                    payload: item,
                });
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: { id: item.id, item, collectionName: 'sampleWorkflows', type: 'PUT' },
                });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: {
                        type: NotificationType.SUCCESS,
                        i18nKey: 'sampleWorkflowCreated',
                        i18nValues: { name: item.name },
                    },
                });
            },
            error: (error: Error) => {
                dispatch({ type: typeFail(ADD_CONFIGURATION_SAMPLE_WORKFLOW), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const editConfigurationWorkflow =
    (id: string | undefined, payload: any, showSuccessSnackbar = true, showErrorSnackbar = true) =>
    (dispatch: Dispatch) => {
        if (!id) {
            return;
        }
        dispatch({ type: typePending(EDIT_CONFIGURATION_SAMPLE_WORKFLOW) });
        return configurationShim.saveWorkflow(id, payload).subscribe({
            next: (item: List) => {
                dispatch({
                    type: typeComplete(EDIT_CONFIGURATION_SAMPLE_WORKFLOW),
                    payload: item,
                });
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: { id: item.id, item, collectionName: 'sampleWorkflows', type: 'PUT' },
                });
                if (showSuccessSnackbar) {
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: {
                            type: NotificationType.SUCCESS,
                            i18nKey: 'sampleWorkflowEdited',
                            i18nValues: { name: item.name },
                        },
                    });
                }
            },
            error: (error: any) => {
                dispatch({
                    type: typeFail(EDIT_CONFIGURATION_SAMPLE_WORKFLOW),
                    payload: { error },
                });
                if (showErrorSnackbar) {
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                }
            },
        });
    };

const cloneConfigurationSampleWorkflow =
    (request: Partial<ConfigurationCreateWorkflowRequest>) => (dispatch: Dispatch) => {
        dispatch({ type: typePending(CLONE_CONFIGURATION_SAMPLE_WORKFLOW), payload: request });
        return configurationShim.addConfigurationSampleWorkflow(request).subscribe({
            next: (response: SampleWorkflow) => {
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id: response.id,
                        item: response,
                        collectionName: 'sampleWorkflows',
                        type: 'PUT',
                    },
                });
                dispatch({
                    type: typeComplete(CLONE_CONFIGURATION_SAMPLE_WORKFLOW),
                    payload: response,
                });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: {
                        type: NotificationType.SUCCESS,
                        i18nKey: 'sampleWorkflowCreated',
                        i18nValues: { name: response.name },
                    },
                });
            },
            error: (error: any) => {
                dispatch({
                    type: typeFail(CLONE_CONFIGURATION_SAMPLE_WORKFLOW),
                    payload: { error },
                });
            },
        });
    };

const deleteConfigurationSampleWorkflow =
    (id: string, showSnackbar = false) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(DELETE_CONFIGURATION_SAMPLE_WORKFLOW), payload: id });
        return configurationShim.deleteSampleWorkflow(id).subscribe({
            next: (response: SampleWorkflow) => {
                const allState = getState() as UserPortalState;
                dispatch({
                    type: typeComplete(DELETE_CONFIGURATION_SAMPLE_WORKFLOW),
                    payload: response,
                });
                dispatch({
                    type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                    payload: {
                        id: response.id,
                        item: response,
                        collectionName: 'sampleWorkflows',
                        type: 'DELETE',
                    },
                });
                dispatchUpdateSelectClear([{ id }], allState, dispatch, true);
                if (showSnackbar) {
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: {
                            type: NotificationType.SUCCESS,
                            i18nKey: 'sampleWorkflowDeleted',
                            i18nValues: { name: response.name },
                        },
                    });
                }
            },
            error: (error: any) => {
                dispatch({
                    type: typeFail(DELETE_CONFIGURATION_SAMPLE_WORKFLOW),
                    payload: { error },
                });
            },
        });
    };

const loadDefaultLabServices = () => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_DEFAULT_LAB_SERVICES) });
    return configurationShim.loadDefaultLabServices().subscribe({
        next: (response: DefaultLabServiceCollections) =>
            dispatch({ type: typeComplete(LOAD_DEFAULT_LAB_SERVICES), payload: response }),
        error: (error: any) =>
            dispatch({ type: typeFail(LOAD_DEFAULT_LAB_SERVICES), payload: { error } }),
    });
};

const importSampleCodes = (payload: any) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(IMPORT_LIST) });
    return configurationShim.importSampleCodes(payload).subscribe({
        next: (response: string[]) => {
            dispatch({
                type: typeComplete(IMPORT_LIST),
                payload: { collection: response, replace: true },
            });

            if (!(response ?? []).length) {
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: {
                        type: NotificationType.SUCCESS,
                        i18nKey: 'sampleCodesImported',
                    },
                });
            }
        },
        error: (error: any) => {
            dispatch({ type: typeFail(IMPORT_LIST), payload: { error } });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const reorderProjectActivities =
    (projectId: string, payload: Record<string, number>, updatedData: Activity[]) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(EDIT_CONFIGURATION_PROJECT_ACTIVITIES) });
        return configurationShim.reorderProjectActivities(projectId, payload).subscribe({
            next: (_result: any) => {
                dispatch({
                    type: typeComplete(EDIT_CONFIGURATION_PROJECT_ACTIVITIES),
                    payload: null,
                });

                updateActivitiesInProjectCollections(projectId, updatedData, dispatch);
            },
            error: (error: any) => {
                dispatch({ type: typeFail(EDIT_CONFIGURATION_PROJECT_ACTIVITIES), payload: error });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const loadConfigurationActivityGroups = () => (dispatch: Dispatch, getState: GetState) => {
    const allState = getState() as UserPortalState;
    const userId = allState.user?.id ?? '';
    const subscriptionId = allState.user?.selected?.id;
    const filters = getFilters(CONFIGURATION_TYPES.ACTIVITY_GROUPS, userId, subscriptionId ?? '');
    dispatch({ type: typePending(LOAD_CONFIGURATION_ACTIVITY_GROUPS) });
    return configurationShim.loadConfigurationActivityGroups(filters).subscribe({
        next: (activityGroupsResponse: any[]) => {
            const activityGroupsUsedInCollection = Object.values(
                allState.subscription.activities ?? {},
            ).reduce((accum: any, value) => {
                Object.keys(value.groups ?? {}).forEach((x) => {
                    if (!(x in accum)) {
                        accum[x] = { usedIn: {} };
                    }

                    accum[x].usedIn[value.id] = { id: value.id, name: value.name };
                });

                return accum;
            }, {});

            removeNotFetchedItemsFromSelectClearState(activityGroupsResponse, allState, dispatch);
            dispatch({
                type: typeComplete(LOAD_CONFIGURATION_ACTIVITY_GROUPS),
                payload: activityGroupsResponse.map((x: any) => ({
                    ...x,
                    usedIn: activityGroupsUsedInCollection[x.id] ?? {},
                })),
            });
        },
        error: (error: Error) => {
            dispatch({ type: typeFail(LOAD_CONFIGURATION_ACTIVITY_GROUPS), payload: { error } });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};
const assignCoordinates =
    (
        payloadArray: AssignCoordinatesPayload[],
        type = EDIT_CONFIGURATION_GRID,
        showErrorSnackbar = true,
        showPendingWarningSnackbar = false,
        successKey: any = {},
        pendingKey: any = {},
        errorDelimiter = ', ',
    ) =>
    (dispatch: Dispatch, getState: GetState) => {
        if (payloadArray.length > 0) {
            dispatch({ type: typePending(type) });
            if (showPendingWarningSnackbar) {
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: {
                        type: NotificationType.WARNING,
                        i18nKey: pendingKey.i18nKey,
                    },
                });
            }
        }

        return observableFrom(payloadArray)
            .pipe(
                mergeMap(
                    (payload: AssignCoordinatesPayload) =>
                        configurationShim
                            .createUpdateDeleteConfigurationCoordinate(
                                payload.coordinateData,
                                payload.payloadType,
                                payload.gridId ?? '',
                            )
                            .pipe(
                                catchError((error) => observableOf(error)),
                                map((result) => ({
                                    ...result,
                                    gridId: payload.coordinateData?.id ?? payload.gridId ?? '',
                                    referenceExisting:
                                        payload.coordinateData?.referencesExisting ??
                                        payload.referenceExisting ??
                                        '',
                                })),
                            ),
                    1,
                ),
            )
            .pipe(toArray())
            .subscribe({
                next: (
                    result: {
                        data: GridEntry;
                        type: Operations;
                    }[],
                ) => {
                    const { allErrors, allValidResponses } = getErrorsFromResponse(
                        result,
                        'data',
                        undefined,
                        errorDelimiter,
                    );
                    const allState = getState() as UserPortalState;
                    const coords = cloneDeep(allState.subscription.coordinates);
                    if (allValidResponses.length > 0) {
                        allValidResponses.forEach((x: { data: GridEntry; type: Operations }) => {
                            if (x.type === 'remove') {
                                delete coords[x.data.id];
                                dispatchUpdateSelectClear([x.data], allState, dispatch, true);
                            } else {
                                coords[x.data.id] = x.data;
                            }
                        });
                        dispatch({
                            type: typeComplete(LOAD_COORDINATES),
                            payload: { result: Object.values(coords) },
                        });
                    }
                    if (allErrors.length > 0) {
                        let accumulatedMessage = '';
                        const dataLossGridIds: string[] = [];
                        const otherErrorMessages: string[] = [];

                        allErrors.forEach((e: any) => {
                            if (e.message.includes('data loss')) {
                                dataLossGridIds.push(e.gridId);
                            } else {
                                otherErrorMessages.push(e.message);
                            }
                        });

                        if (dataLossGridIds.length > 0) {
                            accumulatedMessage +=
                                `Cannot delete grid ${dataLossGridIds.map((id) => coords[id]?.name).join(', ')}. ` +
                                'Deleting would result in data loss.';
                        }
                        accumulatedMessage = `${accumulatedMessage} ${otherErrorMessages.join(errorDelimiter)}`;

                        // Add the ids of the grids that could not be removed or added as part of the error state in order to revert
                        // their selection state in Select From List page
                        dispatch({
                            type: typeFail(type),
                            payload: {
                                error: {
                                    message: accumulatedMessage,
                                    failedIds: allErrors.map((e) => e.referenceExisting),
                                },
                            },
                        });
                        if (showErrorSnackbar) {
                            dispatch({
                                type: ADD_SNACKBAR_NOTIFICATION,
                                payload: {
                                    type: NotificationType.ERROR,
                                    message: { message: accumulatedMessage },
                                },
                            });
                        }
                    } else {
                        dispatch({
                            type: typeComplete(type),
                            payload: {
                                ...([ADD_CONFIGURATION_GRID, DELETE_CONFIGURATION_GRID].includes(
                                    type,
                                ) && {
                                    id: allValidResponses[0]?.data?.id ?? '',
                                }),
                            },
                        });

                        if (successKey.i18nKey) {
                            dispatch({
                                type: ADD_SNACKBAR_NOTIFICATION,
                                payload: {
                                    type: NotificationType.SUCCESS,
                                    i18nKey: successKey.i18nKey,
                                    i18nValues: successKey.i18nValues ?? {},
                                },
                            });
                        }
                    }
                },
            });
    };

const deleteCoordinate = (id: string) =>
    assignCoordinates([{ payloadType: 'remove', gridId: id }], DELETE_CONFIGURATION_GRID, false);
const cloneCoordinate = (updatedCoordinateData: GridEntry) =>
    assignCoordinates(
        [{ payloadType: 'add', coordinateData: updatedCoordinateData }],
        ADD_CONFIGURATION_GRID,
        false,
    );

const loadDefaultProjections =
    (queryString = '') =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(LOAD_DEFAULT_PROJECTIONS) });
        return configurationShim.loadDefaultProjections(queryString).subscribe({
            next: (response) => {
                dispatch({
                    type: typeComplete(LOAD_DEFAULT_PROJECTIONS),
                    payload: response,
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(LOAD_DEFAULT_PROJECTIONS), payload: error });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const loadGridPreview = (payload: Record<string, GridEntry>) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_GRID_PREVIEW) });
    return configurationShim.gridPreview(payload).subscribe({
        next: (response) => {
            dispatch({
                type: typeComplete(LOAD_GRID_PREVIEW),
                payload: response,
            });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(LOAD_GRID_PREVIEW), payload: error });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const updateGridName = (name: string, id: string) => (dispatch: Dispatch, getState: GetState) => {
    dispatch({ type: typePending(UPDATE_GRID_NAME) });
    const allState = getState() as UserPortalState;
    return configurationShim
        .createUpdateDeleteConfigurationCoordinate(
            { ...allState.subscription.coordinates[id], name },
            'edit',
            id,
        )
        .subscribe({
            next: (response) => {
                dispatchUpdateSelectClear(
                    [{ ...response.data, type: response.data.ismx ? 'World' : 'Custom' }],
                    allState,
                    dispatch,
                    false,
                );
                dispatch({
                    type: typeComplete(UPDATE_GRID_NAME),
                    payload: response,
                });
                const coords = allState.subscription.coordinates;
                coords[response.data.id] = response.data;
                dispatch({
                    type: typeComplete(LOAD_COORDINATES),
                    payload: { result: Object.values(coords) },
                });
            },
            error: (error: any) => {
                dispatch({ type: typeFail(UPDATE_GRID_NAME), payload: error });
            },
        });
};

const getUserItems = ({
    users: usersByType,
    account,
}: LoadAccountInformationResponse): UserItem[] => {
    let userItems: UserItem[] = [];

    // Users with "admin" user type are a subset of users with "user" user type so filter them out
    Object.keys(usersByType)
        .filter((type) => type !== UserType.Admin)
        .forEach((type: string) => {
            const userType = type as UserType;
            const users = usersByType[userType as keyof UsersByType];
            users.forEach((user: AccountUser) => {
                userItems = userItems.concat(processUserSubscriptions(user, account, userType));
            });
        });

    userItems = setIsXidAdmin(usersByType.admin, userItems);
    return assignCrossShifter(userItems);
};

const processUserSubscriptions = (
    user: AccountUser,
    account: Account,
    type: UserType,
): UserItem[] => {
    const userItems: UserItem[] = [];

    if (user.subscriptions && !isEmpty(user.subscriptions)) {
        Object.values(user.subscriptions).forEach((userSubscription: AccountUserSubscription) => {
            const subscription = account.subscriptions[userSubscription.subscription];
            if (subscription && (type === UserType.Inactive || subscription?.type === type)) {
                userItems.push({
                    userId: user.id,
                    subscriptionId: subscription.id,
                    role: [
                        userSubscription.role,
                        ...(subscription.expiryDate.date &&
                        subscription.expiryDate.date < Date.now()
                            ? [UserType.Expired]
                            : []),
                    ],
                    profile: user.profile,
                    email: user.email,
                    type,
                    isXidAdmin: false,
                    serial: subscription.identityServerId,
                    frequency: subscription.frequency,
                    expires: subscription.expiryDate,
                    createdAt: subscription.startDate,
                } as UserItem);
            }
        });
    } else {
        userItems.push({
            userId: user.id,
            role: [UserType.Inactive],
            profile: user.profile,
            email: user.email,
            type,
        } as UserItem);
    }

    return userItems;
};

const setIsXidAdmin = (adminUsers: AccountUser[], userItems: UserItem[]): UserItem[] =>
    // Set isXidAdmin to true for users with "admin" user type to disable revoking admin privileges
    userItems.map((item: UserItem) => {
        const isAdmin = adminUsers.some((user: AccountUser) => user.id === item.userId);
        return { ...item, isXidAdmin: isAdmin };
    });
const assignCrossShifter = (userItems: UserItem[]): UserItem[] => {
    const serialToUsersMap: Record<string, UserItem[]> = {};

    // Assign crossShifter to each user with the same subscription serial number
    userItems.forEach((item: UserItem) => {
        if (item.serial) {
            if (!serialToUsersMap[item.serial]) serialToUsersMap[item.serial] = [];
            serialToUsersMap[item.serial].push(item);
        }
    });

    return userItems.map((item: UserItem) => {
        if (item.serial && serialToUsersMap[item.serial].length > 1) {
            const crossShifter = serialToUsersMap[item.serial].find(
                (user) => user.userId !== item.userId,
            );
            if (crossShifter) return { ...item, crossShifter: crossShifter.profile.name };
        }
        return item;
    });
};

const loadConfigurationUsers = () => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_CONFIGURATION_USERS) });
    return configurationShim.loadAccountInformation().subscribe({
        next: ({ result }: { result: LoadAccountInformationResponse }) => {
            dispatch({
                type: typeComplete(LOAD_CONFIGURATION_USERS),
                payload: { items: getUserItems(result) },
            });
        },
        error: (err: any) => {
            dispatch({ type: typeFail(LOAD_CONFIGURATION_USERS), payload: err });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: err },
            });
        },
    });
};

const modifyAdminPrivileges = (item: DataRowType) => (dispatch: Dispatch, getState: GetState) => {
    dispatch({ type: typePending(OP_CONFIGURATION_USERS) });
    const newRole = item.role?.includes(UserType.Admin) ? UserType.User : UserType.Admin;
    const payload = {
        user: item.userId ?? '',
        subscription: item.subId ?? '',
        role: newRole,
    };

    return configurationShim.modifyAdminPrivileges(payload).subscribe({
        next: (response: AccountUser) => {
            const curUsers = getState().configuration?.usersState?.items;
            if (curUsers) {
                const user = curUsers.find(
                    (user) =>
                        user.userId === payload.user &&
                        user.subscriptionId === payload.subscription,
                );
                if (user) {
                    user.role = [payload.role];
                }
            }
            dispatch({ type: typeComplete(OP_CONFIGURATION_USERS), payload: { items: curUsers } });

            const allState = getState() as UserPortalState;
            dispatchUpdateSelectClear([{ ...item, role: [newRole] }], allState, dispatch, false);
            dispatch({
                type: typeComplete(CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE),
                payload: {
                    id: payload.user,
                    item: {
                        id: payload.subscription,
                        ...response.subscriptions?.[payload.subscription],
                    },
                    collectionName: 'users',
                    keyInCollection: 'subscriptions',
                },
            });
        },
        error: (error: any) => {
            dispatch({
                type: typeFail(OP_CONFIGURATION_USERS),
                payload: error,
            });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const deleteConfigurationActivityGroup = (id: string) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(ADD_EDIT_DELETE_ACTIVITY_GROUPS), payload: id });
    return configurationShim.deleteConfigurationActivityGroup(id).subscribe({
        next: (response: ActivityGroupMap) => {
            const allState = getState() as UserPortalState;
            dispatch({
                type: typeComplete(ADD_EDIT_DELETE_ACTIVITY_GROUPS),
                payload: { data: response, operationType: 'delete' },
            });
            dispatch({
                type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                payload: {
                    id: response.id,
                    item: response,
                    collectionName: 'activityGroups',
                    type: 'DELETE',
                },
            });
            dispatchUpdateSelectClear([{ id }], allState, dispatch, true);
        },
        error: (error: any) => {
            dispatch({
                type: typeFail(ADD_EDIT_DELETE_ACTIVITY_GROUPS),
                payload: error,
            });
        },
    });
};

const addEditActivityGroup = (name: string, id?: string) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(ADD_EDIT_DELETE_ACTIVITY_GROUPS) });
    return configurationShim.addEditConfigurationActivityGroups({ name }, id).subscribe({
        next: (response) => {
            dispatch({
                type: typeComplete(ADD_EDIT_DELETE_ACTIVITY_GROUPS),
                payload: { data: response, operationType: !id ? 'add' : 'edit' },
            });
            dispatch({
                type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE),
                payload: {
                    id: response.id,
                    item: response,
                    collectionName: 'activityGroups',
                },
            });
            if (id) {
                const allState = getState() as UserPortalState;
                dispatchUpdateSelectClear([response], allState, dispatch, false);
            }
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: {
                    type: NotificationType.SUCCESS,
                    i18nKey: !id ? 'activityGroupCreated' : 'activityGroupUpdated',
                    i18nValues: { name: response.name },
                },
            });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(ADD_EDIT_DELETE_ACTIVITY_GROUPS), payload: error });
        },
    });
};

const loadConfigurationCategories = () => (dispatch: Dispatch, getState: GetState) => {
    const allState = getState() as UserPortalState;
    const userId = allState.user?.id ?? '';
    const subscriptionId = allState.user?.selected?.id;
    const filters = getFilters(CONFIGURATION_TYPES.CATEGORIES, userId, subscriptionId ?? '');

    const headerAdditionalFilter = (isSample: boolean) => ({
        type: 'list',
        operator: 'one_of',
        values: [isSample ? HEADER_TYPES.DISPATCH.toString() : HEADER_TYPES.LOGGING.toString()],
        field: 'type',
    });

    const moduleFilter = filters.find((x) => x.field === 'module');

    const categories = [
        { type: CATEGORY_TYPES.LIST },
        { type: CATEGORY_TYPES.TABLE },
        { type: CATEGORY_TYPES.HEADER, additionalFilter: headerAdditionalFilter(false) },
        { type: CATEGORY_TYPES.DISPATCH_HEADER, additionalFilter: headerAdditionalFilter(true) },
        { type: CATEGORY_TYPES.ACTIVITY },
    ].filter(
        (x) =>
            !moduleFilter ||
            moduleFilter.operator !== 'one_of' ||
            moduleFilter.values.includes(ALL_ITEM_KEY) ||
            moduleFilter.values.includes(x.type),
    );

    dispatch({ type: typePending(LOAD_CONFIGURATION_CATEGORIES) });

    return observableFrom(categories)
        .pipe(
            mergeMap(
                (x) =>
                    configurationShim.loadConfigurationCategories(
                        [
                            ...filters.filter((x) => x.field !== 'module'),
                            ...(x.additionalFilter ? [x.additionalFilter] : []),
                        ],
                        x.type,
                    ),
                5,
            ),
        )
        .pipe(toArray())
        .subscribe({
            next: (categoriesResponse) => {
                const categoryMap = [
                    ...Object.values(allState.subscription.activities ?? {}),
                    ...Object.values(allState.subscription.sampleDispatchHeaders ?? {}),
                    ...Object.values(allState.subscription.headers ?? {}),
                    ...Object.values(allState.subscription.lists ?? {}),
                    ...Object.values(allState.subscription.tableViews ?? {}),
                ].reduce((accum, value) => {
                    if (value.category) {
                        if (value.category in accum) {
                            accum[value.category ?? ''].usedIn[value.id] = {
                                id: value.id,
                                name: value.name,
                            };
                        } else {
                            accum[value.category ?? ''] = {
                                usedIn: { [value.id]: { id: value.id, name: value.name } },
                            };
                        }
                    }

                    return accum;
                }, {} as any);

                const categories = categoriesResponse
                    .reduce((accum, value) => accum.concat(value), [] as any[])
                    .map((x) => ({ ...x, usedIn: categoryMap[x.id] ?? {} }));

                const filteredCategories =
                    moduleFilter && moduleFilter?.operator === 'empty' ? [] : categories;

                removeNotFetchedItemsFromSelectClearState(filteredCategories, allState, dispatch);

                dispatch({
                    type: typeComplete(LOAD_CONFIGURATION_CATEGORIES),
                    payload: {
                        items: filteredCategories,
                    },
                });
            },
            error: (error: any) => {
                dispatch({
                    type: typeFail(LOAD_CONFIGURATION_CATEGORIES),
                    payload: { error },
                });
            },
        });
};

const configurationCategoriesOperation =
    (operation: Operations, payloads: any[], successKey = '') =>
    (dispatch: Dispatch, getState: GetState) => {
        dispatch({ type: typePending(CONFIGURATION_CATEGORIES_OPERATION) });
        return observableFrom(payloads)
            .pipe(
                mergeMap((x) => {
                    const { categoryType, ...rest } = x;

                    return configurationShim
                        .modifyConfigurationCategory(operation, categoryType, rest, rest?.id ?? '')
                        .pipe(catchError((error) => observableOf({ ...error, categoryType })));
                }, 5),
            )
            .pipe(toArray())
            .subscribe({
                next: (result) => {
                    const { allErrors, allValidResponses, errorsArray } = getErrorsFromResponse(
                        result,
                        'data',
                    );

                    const allState = getState() as UserPortalState;
                    const categoriesItem = [...allState.configuration.categoriesState.items];
                    const updateMap: any = {
                        activityCategories: [],
                        headerCategories: [],
                        listCategories: [],
                        tableCategories: [],
                    };

                    if (allValidResponses.length > 0) {
                        allValidResponses.forEach((x) => {
                            const modifiedType =
                                x.data.categoryType === CATEGORY_TYPES.DISPATCH_HEADER
                                    ? CATEGORY_TYPES.HEADER
                                    : x.data.categoryType;

                            updateMap[`${(modifiedType as string).toLowerCase()}Categories`].push(
                                x.data,
                            );
                            const categoryIndex = categoriesItem.findIndex(
                                (y) => y.id === x.data.id,
                            );

                            if (operation === 'remove') {
                                dispatchUpdateSelectClear([x.data], allState, dispatch, true);
                                categoriesItem.splice(categoryIndex, 1);
                            } else if (operation === 'edit') {
                                categoriesItem[categoryIndex] = {
                                    ...categoriesItem[categoryIndex],
                                    ...x.data,
                                    type: x.data.categoryType,
                                };
                                dispatchUpdateSelectClear(
                                    [categoriesItem[categoryIndex]],
                                    allState,
                                    dispatch,
                                    false,
                                );
                            } else {
                                categoriesItem.push(x.data);
                            }
                        });

                        Object.entries(updateMap).forEach(([key, value]) =>
                            dispatch({
                                type: typeComplete(
                                    CREATE_UPDATE_DELETE_COLLECTION_ITEM_LIST_SUBSCRIPTION_STATE,
                                ),
                                payload: {
                                    items: value,
                                    collectionName: key,
                                    type: operation === 'remove' ? 'DELETE' : 'PUT',
                                },
                            }),
                        );
                    }
                    if (allErrors.length > 0) {
                        const modifiedErrorsArray =
                            operation === 'add'
                                ? allErrors.map(
                                      (x, idx) =>
                                          `Unable to create ${x.categoryType.toLowerCase()} category: ${errorsArray[idx]}`,
                                  )
                                : errorsArray;

                        dispatch({
                            type: typeFail(CONFIGURATION_CATEGORIES_OPERATION),
                            payload: { error: { message: modifiedErrorsArray.join(', ') } },
                        });
                    } else {
                        dispatch({
                            type: typeComplete(CONFIGURATION_CATEGORIES_OPERATION),
                            payload: {
                                items: categoriesItem,
                            },
                        });

                        if (successKey) {
                            dispatch({
                                type: ADD_SNACKBAR_NOTIFICATION,
                                payload: {
                                    type: NotificationType.SUCCESS,
                                    i18nKey: successKey,
                                    i18nValues: { name: payloads[0]?.name ?? '' },
                                },
                            });
                        }
                    }
                },
            });
    };

const deleteCategory =
    (id: string, categoryType: CATEGORY_TYPES) =>
    (dispatch: Dispatch, getState: () => UserPortalState) =>
        configurationCategoriesOperation('remove', [{ id, categoryType }])(dispatch, getState);

const editCategory =
    (name: string, id: string, item: any) => (dispatch: Dispatch, getState: GetState) =>
        configurationCategoriesOperation(
            'edit',
            [
                {
                    id,
                    name,
                    categoryType: item.type,
                },
            ],
            'categoryUpdated',
        )(dispatch, getState);

const setShouldReload = (stateName: CONFIGURATION_TYPES) => (dispatch: Dispatch) =>
    dispatch({ type: RELOAD_CONFIGURATION_ITEMS, payload: { stateName } });

const loadEvoWorkspaces = () => (dispatch: any) => {
    dispatch({ type: typePending(LOAD_EVO_WORKSPACES) });
    return configurationShim.loadEvoWorkspaces().subscribe({
        next: (response: any) => {
            dispatch({ type: typeComplete(LOAD_EVO_WORKSPACES), payload: response });
        },
        error: (error: any) => {
            dispatch({
                type: typeFail(LOAD_EVO_WORKSPACES),
                payload: error,
            });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const loadEvoOrg = () => (dispatch: any) => {
    dispatch({ type: typePending(LOAD_EVO_ORG) });
    return configurationShim.loadEvoOrg().subscribe({
        next: (response: any) => {
            dispatch({ type: typeComplete(LOAD_EVO_ORG), payload: response });
        },
        error: (error: any) => {
            dispatch({
                type: typeFail(LOAD_EVO_ORG),
                payload: error,
            });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const evoConfiguration = (payload?: EvoConfigurationState) => (dispatch: any) => {
    const type = payload ? 'POST' : 'GET';
    dispatch({ type: typePending(EVO_CONFIGURATION) });
    return configurationShim.evoConfiguration(type, payload).subscribe({
        next: (response: any) => {
            dispatch({ type: typeComplete(EVO_CONFIGURATION), payload: response });
        },
        error: (error: any) => {
            dispatch({
                type: typeFail(EVO_CONFIGURATION),
                payload: error,
            });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const loadEvoDiscovery = () => (dispatch: any) => {
    dispatch({ type: typePending(EVO_DISCOVERY) });
    return configurationShim.loadEvoDiscovery().subscribe({
        next: (response: any) => {
            dispatch({ type: typeComplete(EVO_DISCOVERY), payload: response });
        },
        error: (error: any) => {
            dispatch({
                type: typeFail(EVO_DISCOVERY),
                payload: error,
            });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

export const actions = {
    addCertificateImport,
    deleteCertificateImport,
    editCertificateImport,
    addConfigurationHeaderFieldType,
    loadConfigurationHeaderFieldTypes,
    loadConfigurationLists,
    loadConfigurationTableViews,
    loadConfigurationHeaders,
    loadConfigurationUsers,
    clearHeaderFieldTypesItems,
    clearHeadersItems,
    clearTablesItems,
    clearListsItems,
    clearGridsItems,
    clearActivityItems,
    clearProjectItems,
    clearActivityGroupsItems,
    clearConfigurationSection,
    deleteConfigurationList,
    addConfigurationList,
    deleteConfigurationTableView,
    addConfigurationTable,
    clearConfigurationOperationState,
    modifyListRow,
    modifyListRowWithValidation,
    deleteListRows,
    saveList,
    reorderColumns,
    editList,
    editTable,
    editHeader,
    cloneConfigurationList,
    cloneConfigurationActivity,
    modifyColumn,
    modifyCoordinateColumn,
    clearModifyColumn,
    deleteTableViewTableColumn,
    deleteColumn,
    addTableToTableView,
    reorderTables,
    deleteTableViewTable,
    addConfigurationHeader,
    deleteConfigurationHeader,
    deleteFieldFromHeader,
    addFieldToHeaderDefaultSectionAction,
    deleteSectionFromHeader,
    swapHeaderSection,
    updateHeaderSections,
    addHeaderSection,
    modifyHeaderSection,
    moveFieldWithinHeaderSection,
    reorderCoordinatesTable,
    deleteCoordinatesTableColumn,
    moveFieldBetweenHeaderSections,
    deleteConfigurationField,
    clearHeaderFieldTypesAddState,
    clearHeaderFieldTypesDeleteState,
    importList,
    bulkValidateImportList,
    bulkImportList,
    confirmImportColumnTypes,
    deleteImportedList,
    configurationExport,
    clearConfigurationListsImportListState,
    clearConfigurationExportState,
    importTable,
    clearConfigurationTablesImportTableState,
    loadConfigurationActivities,
    loadConfigurationGrids,
    deleteConfigurationActivity,
    reorderActivityTables,
    addConfigurationActivity,
    editConfigurationActivity,
    modifyAndReorderConfigurationActivity,
    multiSaveTableViewSpecs,
    modifyConfigurationActivityListSpecs,
    loadPossibleSampleResultsColumns,
    loadConfigurationRankedColumns,
    clearRankedColumnsState,
    clearPossibleColumns,
    addSampleResultsRankedColumn,
    modifySampleWorkflows,
    editRankedColumn,
    recomputeSampleResults,
    updateActualDepth,
    deleteRankedColumn,
    modifyColumnAssociations,
    loadConfigurationProjects,
    addConfigurationProject,
    deleteConfigurationProject,
    editConfigurationProject,
    convertProjectBoundaries,
    addConfigurationProjectActivity,
    editConfigurationProjectActivities,
    deleteConfigurationProjectActivity,
    assignUnassignUsers,
    toggleShowBoundaryInDecimal,
    uploadProjectThumbnail,
    deleteProjectThumbnail,
    loadProjectUserPermissions,
    modifyProjectActivityUserPermission,
    loadListFileGroup,
    createListFileGroupAndSaveRow,
    uploadFileToListGroup,
    downloadListFile,
    deleteListFile,
    clearListFileState,
    loadEmptyListFileGroups,
    loadConfigurationSampleWorkflows,
    clearConfigurationSampleWorkflowState,
    clearConfigurationSampleWorkflowAddState,
    addConfigurationSampleWorkflow,
    editConfigurationWorkflow,
    cloneConfigurationSampleWorkflow,
    deleteConfigurationSampleWorkflow,
    loadDefaultLabServices,
    importSampleCodes,
    reorderProjectActivities,
    loadConfigurationCategories,
    loadConfigurationActivityGroups,
    deleteConfigurationActivityGroup,
    assignCoordinates,
    deleteCoordinate,
    cloneCoordinate,
    loadDefaultProjections,
    loadGridPreview,
    updateGridName,
    modifyAdminPrivileges,
    configurationCategoriesOperation,
    deleteCategory,
    editCategory,
    addEditActivityGroup,
    setShouldReload,
    loadEvoWorkspaces,
    loadEvoOrg,
    evoConfiguration,
    loadEvoDiscovery,
};
